import React from "react";

import loaderGif from "../Contents/images/loader.gif";
import { useStateValue } from "../Context/StateProvider";

const FetchLoader = () => {
	const [{ fetching }] = useStateValue();
	return (
		fetching ? (
			<div className="backdrop">
				<div className="spinner">
					<img src={loaderGif} alt="loading..." />
				</div>
			</div>
		) : null
	)
}

export default FetchLoader