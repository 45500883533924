import React from "react";
import axios from "axios";
import { MdKeyboardBackspace, MdOutlineClose } from "react-icons/md";
import { VscDebugDisconnect } from "react-icons/vsc";
import { useStateValue } from "../Context/StateProvider";
import Button from "./Button";

const DisconnectWallet = () => {
    const [{ API_URL, showDisconnectWallet }, payload] = useStateValue();

    const closeScreen = () => {
        payload({
            type: "WALLET_DISCONNECT_VISIBILITY",
            showDisconnectWallet: false
        });
    }
    const disConnectWallet = async () => {
        try {
            // end wallet session on server
            axios.defaults.withCredentials = true;
            await axios.delete(`${API_URL}/wallet`);
            payload({
                type: "WALLET",
                wallet: null
            });
            closeScreen();
            payload({
                type: "NOTIFICATION",
                notification: { status: "warning", message: "Wallet Disconnected" }
            });
        } catch(err) {
            console.log(err)
            payload({
                type: "NOTIFICATION",
                notification: { status: "error", message: err.response.statusText || err.response.data.message || err.message }
            });
        }
    }

    return (
        showDisconnectWallet ? (

            <div className="backdrop">
                <div className="modal">
                    <div className="modal-header flex-box flex-align-center-box flex-justify-apart">
                        <div>
                            <div className="bold-text secondary-color-text">Import Wallet</div>
                            <div className="small-text grey-color-text">Import your wallet to interact with the DEX</div>
                        </div>
                        <MdOutlineClose onClick={closeScreen} />
                    </div>
                    <div className="modal-body">
                        <div className="modal-content">
                            <div className="illustration">
                                {/* <img src={techface} alt="" /> */}
                            </div>
                            <div className="large-text bold-text centered-text">
                                Disconnect wallet
                            </div>
                            <div className="side-bar small-text">
                                Buy, Sell, Swap and Stake 500+ crypto assets <br />
                                Multi-Chain & NFT support<br />
                                Browser native, no risky extensions
                            </div>
                            <div className="options">
                                <Button
                                    className="transparent-btn return-btn"
                                    innerHTML="Back"
                                    clickMethod={closeScreen}
                                    leftIcon={<MdKeyboardBackspace />}
                                />
                                <Button
                                    className="danger bold-text"
                                    innerHTML="Disconnect"
                                    leftIcon={<VscDebugDisconnect />}
                                    clickMethod={disConnectWallet}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default DisconnectWallet