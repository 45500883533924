import React from "react";
import Grid from "./Grid";

const PoolsGrid = ({ pools, showOnlyStaked }) => {

    return (
        <div className="full-width">
            {
                pools.length > 0 ? 
                    <div className="full-width grid-box grid-box-3x flex-gap-large">
                    {pools?.map((pool, key) => <Grid key={key} pool={pool} showOnlyStaked={showOnlyStaked} />)}
                    </div>
                : 
                    <div className="full-width flex-box flex-align-justify-center-box">No pool item(s) found.</div>
                
            }
        </div>
    )
}

export default PoolsGrid