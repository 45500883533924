import React, { Fragment } from "react";
import Header from "../Components/Header";
import DexManager from "../Components/DexManager";
import { useParams } from "react-router-dom";
import Farm from "../Components/Farm";
import Pools from "../Components/Pools";
import MobileFooterMenu from "../Components/MobileFooterMenu";

const Trade = () => {
    const tradeType = useParams().tradeType;
    return (
        <Fragment>
            <Header />
            <main className="spaced-btm">
            {
                tradeType == "swap" ? (<DexManager />) : 
                tradeType == "farm" ? (<Farm />) :
                tradeType == "pools" ? (<Pools />) :
                <DexManager />
            }   
                
            </main>
            <MobileFooterMenu />
        </Fragment>
    )
}

export default Trade