import React, { Fragment, useState, useEffect, useRef } from "react";
import { motion, useScroll } from "framer-motion";

import swap from "../Contents/images/swap.png";
import earn from "../Contents/images/earn.png";
import liquidity from "../Contents/images/liquidity.png";
import { useStateValue } from "../Context/StateProvider";
import axios from "axios";
import { Link } from "react-router-dom";


const variants = {
    rotate90: { rotateX: 90 },
    rotate0: {
        rotateX: 0,
        transition: {
            ease: "easeIn"
        }
    },
    pos: { x: "0" },
    posRight100: { x: "100%" },
    posLeft100: { x: "-100%" },
}

const Image = ({ image }) => {
    const scrollRef = useRef();
    return (
        <motion.div
            ref={scrollRef}
            className="desc-image"
            initial={variants.rotate90}
            whileInView={variants.rotate0}
        >
            <img src={image} alt="" />
        </motion.div>
    )
}

const Text = ({ serviceTitle, serviceDesc }) => {
    const slideRef = useRef();
    return (
        <motion.div
            ref={slideRef}
            initial={variants.posRight100}
            whileInView={variants.pos}
            className="desc-content">
            <div className="desc-title">{serviceTitle}</div>
            <div className="desc-note">{serviceDesc}</div>
        </motion.div>
    )
}

const ScrollImageEffect = ({ serviceTitle, serviceDesc, image, imageLeft = true }) => {
    return (
        <motion.div className="service-row full-width">
            {
                imageLeft ? (
                    <Fragment>
                        <Image image={image} />
                        <Text serviceTitle={serviceTitle} serviceDesc={serviceDesc} />
                    </Fragment>
                ) : (
                    <Fragment>
                        <Text serviceTitle={serviceTitle} serviceDesc={serviceDesc} />
                        <Image image={image} />
                    </Fragment>
                )
            }
        </motion.div>
    )
}

const Services = () => {
    const [{ API_URL }] = useStateValue();
    const [scrollY, setScrollY] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pools, setPools] = useState(null);

    const scrollRef1 = useRef();
    const scrollRef2 = useRef();
    const scrollRef3 = useRef();

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };
    const getPools = async (source) => {
        try {
            const { data: response } = await axios.get(`${API_URL}/pools/chainId/1?limit=10`, { cancelToken: source.token });
            const { pools } = response;
            setPools(pools);
        } catch (err) {
            console.log(err.message);
        }
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        const source = axios.CancelToken.source();
        getPools({ source });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            source.cancel();
        };
    }, []);

    return (
        <Fragment>
            <section className="container services light-color-background">

                {/* <ScrollImageEffect 
                    serviceTitle={<span>Swap Tokens <span className="primary-color-text">Seamlessly</span></span>} 
                    serviceDesc="Seamlessly swap crypto tokens with our Advanced Automated Market Maker (AAMM) technology for efficient and reliable trading experiences."
                    image={swap} />
                <ScrollImageEffect 
                    serviceTitle={<span>Earn <span className="primary-color-text">Passive</span> Income</span>} 
                    serviceDesc="Earn passive income effortlessly through our app by leveraging cutting-edge cryptocurrency staking and yield farming technologies."
                    image={earn}
                    imageLeft={false} />
                <ScrollImageEffect 
                    serviceTitle={<span>Automation and <span className="primary-color-text">Liquidity Pool</span> Management</span>} 
                    serviceDesc="Master liquidity pool management with our app's advanced, intuitive technology designed for optimal cryptocurrency handling and investment growth."
                    image={liquidity} /> */}
                <div className="container-1028 liquidity-box dark-color-text">
                    <div className="box-max-300">
                        <div className="large-text bold-text">
                            More than 130+ liquidity sources
                        </div>
                        <div className="large-x-mg-box small-text">
                            Zero fee trades on 5+ million tokens across 3 chains and counting. Get the best prices by tapping into 130+ liquidity sources.
                        </div>
                    </div>
                    <div className="full-width flex-box flex-align-justify-center-box">
                        <div className="box-max-500">
                            {
                                pools && pools.length > 0 ?
                                    <div className="full-width overflow-hidden">
                                        <div className="home-pool full-width grid-box grid-box-2 flex-gap-medium large-x-mg-box">
                                            {pools?.map(({ stake_name, stake_symbol, stake_thumbnail }, key) =>
                                                <div key={key} className="home-pool-item full-width flex-box flex-align-baseline-box flex-gap-large primary-color-background medium-z-pd-box">
                                                    <div className="small-icon">
                                                        <img src={stake_thumbnail} alt={stake_symbol} height={20} />
                                                    </div>
                                                    <div className="bold-text">{stake_name}</div>
                                                    <div className="link">
                                                        <Link to="/trade/pools">Trade {stake_name}</Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="full-width animate-slide-right">
                                            <Link className="secondary-color-background large-z-pd-box" to="/trade/pools">Explore 6000+ pool options</Link>
                                        </div>
                                    </div> :
                                    <div className="bold-text">
                                        No pool(s) found!
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Services