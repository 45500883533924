import React from "react";

const Button = ({innerHTML, leftIcon, rightIcon, clickMethod, className, disabled}) => {
    return (
        <button
            className={`btn ${className || ""}`}
            onClick={clickMethod}
            disabled={disabled}
        >
            {leftIcon}
            {innerHTML}
            {rightIcon}
        </button>
    )
}

export default Button