import React, { Fragment } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Routes/Home";
import Trade from "./Routes/Trade";
import Header from "./Components/Header";
import NotFound from "./Components/NotFound";

import "./Styles/Styles.css";
import "./Styles/Media.css";
import "./Styles/Animations.css";
import Notifications from "./Components/Notifications";
import ConnectWallet from "./Components/ConnectWallet";
import Settings from "./Components/Settings";
import DisconnectWallet from "./Components/DisconnectWallet";
import FetchLoader from "./Components/FetchLoader";
import TermsOfUse from "./Routes/TermsOfUse";
import PlatformPolicies from "./Routes/PlatformPolicies";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/trade",
        element: <Trade />,
    },
    {
        path: "/trade/:tradeType",
        element: <Trade />,
    },
    {
        path: "/terms-of-use",
        element: <TermsOfUse />,
    },
    {
        path: "/platform-policies",
        element: <PlatformPolicies />,
    },
    {
        path: "*",
        element: <NotFound />,
    }
]);

const App = () => {   
    return (
        <Fragment>
            <Notifications />
            <ConnectWallet />
            <DisconnectWallet />
            <Settings />
            <RouterProvider router={router} />
            <FetchLoader />
        </Fragment>
    )
}

export default App