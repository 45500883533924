import React, { act, Fragment, useEffect, useRef, useState } from "react";
import { TbSettingsBolt } from "react-icons/tb";
import { useStateValue } from "../Context/StateProvider";
import { GrGrow } from "react-icons/gr";
import { MdOutlineKeyboardArrowDown, MdOutlineRocketLaunch } from "react-icons/md";
import placeHolderCoin from "../Contents/images/placeholder-coin.png";
import promo from "../Contents/images/promo.png";
import Button from "./Button";
import TokensSelector from "./TokensSelector";
import axios from "axios";
import { GiPlantSeed, GiVerticalBanner } from "react-icons/gi";
import etherLogo from "../Contents/images/ether-logo.png";
import { PiPlantFill } from "react-icons/pi";
import { formatNumber, formatToCurrency, loadImage, scrollToPos } from "../Context/general";
import ConfirmTrade from "./ConfirmTrade";
import loaderGif from "../Contents/images/loader.gif";
import Farmed from "./Farmed";

const Farm = () => {
    const [{ API_URL, SITE_NAME, wallet, networks, tokens, activeNetwork }, payload] = useStateValue();
    const [baseAsset, setBaseAsset] = useState(null);
    const [quoteAsset, setQuoteAsset] = useState(null);
    const [tokenSelector, setTokenSelector] = useState("");
    const [networkTokens, setNetworkTokens] = useState(null);
    const [farmed, setFarmed] = useState([]);
    const [showNetOptions, setShowNetOptions] = useState(false);
    const [isLoadingToken, setIsLoadingToken] = useState(false);
    const [netTokenUsdValue, setNetTokenUsdValue] = useState(1);
    const [tradeConfirmation, setTradeConfirmation] = useState(null);
    const [tradeFee, setTradeFee] = useState(0);
    const [netTradeFee, setNetTradeFee] = useState(0);

    const netRef = useRef();
    const farmForm = useRef();
    const isRequestInProgress = useRef(false);
    const isFeeRequestInProgress = useRef(false);

    const scrollToAddLiquidity = (liquidity) => {
        const base = tokens[activeNetwork.symbol.toLowerCase()].filter((token) => token.address.toLowerCase() === liquidity.data.base.toLowerCase())[0];
        const quote = tokens[activeNetwork.symbol.toLowerCase()].filter((token) => token.address.toLowerCase() === liquidity.data.quote.toLowerCase())[0];
        
        setBaseAsset(base);
        setQuoteAsset(quote);
        scrollToPos(farmForm.current.offsetTop);
    }
    const harvestLiquidity = async (liquidity) => {
        console.log(liquidity);
    }
    const checkTx = (base, quote) => {
        if (parseFloat(base.amount) > 0) {
            if (parseFloat(base.amount) > parseFloat(base.balance)) {
                return { hasError: true, message: `Insufficient ${base.symbol} token(s) balance` }
            }
            if (parseFloat(quote.amount) > parseFloat(quote.balance)) {
                return { hasError: true, message: `Insufficient ${quote.symbol} token(s) balance` }
            }
        }
        return {};
    }
    const showTokenSelector = (category) => {
        if (category == "") {
            setTokenSelector("");
        }
        if (["base", "quote"].includes(category)) {
            const netTokens = tokens[activeNetwork.symbol.toLowerCase()];

            setTokenSelector(
                <TokensSelector
                    tokens={netTokens}
                    base={baseAsset.symbol.toLowerCase()}
                    quote={quoteAsset.symbol.toLowerCase()}
                    category={category}
                    selectMethod={(token) => handleTokenSelection(token, category)}
                    closeSelector={() => setTokenSelector("")}
                />
            );
        }
    }
    const handleTokenSelection = async (token, category) => {
        try {
            if (category == "base") {
                setBaseAsset(token);
                setQuoteAsset({ ...quoteAsset, amount: "" });
            }
            if (category == "quote") {
                setQuoteAsset(token);
                setBaseAsset({ ...baseAsset, amount: "" });
            }
            // token.price = price;
            setTokenPrice(token, category, "");
        } catch (err) { }
        setTokenSelector("");
    }
    const handleNetSelection = (network) => {
        if (activeNetwork.symbol == network.symbol) {
            return;
        }
        payload({ type: "ACTIVE-NETWORK", activeNetwork: network });
        setActiveNetworkMethod(network);
        setShowNetOptions(false);
    }
    const handleInputOnChange = (e) => {
        let value = e.target.value;
        if (value != "") {
            value = value.toString().replace(" ", "").split("").reduce((p, c) => {
                if (!isNaN(c) || (c == "." && !p.split("").includes("."))) p += c;
                return p;
            }, "");
        }
        setQuoteAsset({ ...quoteAsset, amount: value });
        const baseValue = (parseFloat(quoteAsset.price) / parseFloat(baseAsset.price)) * parseFloat(value);
        setBaseAsset({ ...baseAsset, amount: baseValue });
        setNetTradeFee((((parseFloat(baseValue) * parseFloat(baseAsset?.price)) / netTokenUsdValue) * 0.9) / 100);
    }
    const addLiquidity = () => {
        if (parseFloat(baseAsset.amount) > 0) {
            setTradeConfirmation(
                <ConfirmTrade
                    tradeSlippage={0}
                    tradeMethod="Add Liquidity"
                    baseAsset={baseAsset}
                    quoteAsset={quoteAsset}
                    onSubmit={submitLiquidity}
                    activeNetwork={activeNetwork}
                    netTokenUsdValue={netTokenUsdValue}
                    tradeFee={parseFloat(tradeFee) + parseFloat(netTradeFee)}
                    closeMethod={() => setTradeConfirmation(null)} />
            );
        }
    }
    const submitLiquidity = async (passCode) => {
        payload({
            type: "FETCHING",
            fetching: true
        });
        try {
            axios.defaults.withCredentials = true;
            const { address, amount } = quoteAsset;
            let method = "add liquidity";

            const transactionObject = {
                method,
                baseAsset,
                quoteAsset,
                fee: tradeFee,
                asset: address,
                sender: wallet,
                txAuth: passCode,
                amount: parseFloat(amount),
                chain_id: activeNetwork.chain_id
            }

            await axios.post(`${API_URL}/transactions`, transactionObject);
            const newBaseAsset = { ...baseAsset, amount: "" };
            const newQuoteAsset = { ...quoteAsset, amount: "" };
            setPairPrice(newBaseAsset, newQuoteAsset);
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "success",
                    message: <span>Swapped ${baseAsset.amount} ${baseAsset.symbol.toUpperCase()} for ${quoteAsset.amount} ${quoteAsset.symbol.toUpperCase()} successfully.<br /><a href={`${activeNetwork.explorer}/`}>View transaction on explorer</a></span>
                }
            });
        } catch (err) {
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "error",
                    message: err.response.data.message || err.response.statusText || err.message
                }
            });
        } finally {
            payload({
                type: "FETCHING",
                fetching: false
            });
            setTradeConfirmation(null);
        }
    }
    const getNetworks = (source = null) => new Promise(async function (resolve) {
        if (networks != null) return resolve(networks);
        try {
            const { data: response } = source == null ?
                await axios.get(`${API_URL}/networks`) :
                await axios.get(`${API_URL}/networks`, { cancelToken: source.token });
            const { networks } = response;
            payload({
                type: "NETWORKS",
                networks
            });
            if (networks.length > 0 && activeNetwork == null) {
                payload({
                    type: "ACTIVE-NETWORK",
                    activeNetwork: networks.filter(function (network) { return network.symbol.toLowerCase() == "eth" })[0]
                });
            }
            return resolve(networks);
        } catch (err) {
            // console.error(err.message);
            return resolve("FAIL");
        }
    })
    const getTokens = (source = null, netChoice = null) => new Promise(async function (resolve) {
        if (isRequestInProgress.current) return;
        isRequestInProgress.current = true;
        try {
            let _activeNetwork = netChoice || activeNetwork;
            if (activeNetwork == null) {
                const _networks = await getNetworks(source);
                _activeNetwork = _networks[0];
            }
            let _tokens = tokens
            if (_tokens == null) {
                setIsLoadingToken(true);
                const { data: response } = source == null ?
                    await axios.get(`${API_URL}/tokens`) :
                    await axios.get(`${API_URL}/tokens`, { cancelToken: source.token });
                _tokens = response.tokens;
                setIsLoadingToken(false);
            }

            payload({
                type: "TOKENS",
                tokens: _tokens
            });
            const selectedNetworkTokens = _tokens[_activeNetwork?.symbol.toLowerCase() || "eth"];
            if (selectedNetworkTokens.length > 1) {
                const base = selectedNetworkTokens.filter(token => token.symbol.toLowerCase() === _activeNetwork.symbol.toLowerCase())[0];
                const quote = selectedNetworkTokens.filter(token => ["dai", "usdt", "usdc", "busd"].includes(token.symbol.toLowerCase()))[0];

                setPairPrice(base, quote, source);
            }
            isRequestInProgress.current = false;
            return resolve(_tokens);
        } catch (err) {
            console.error(err.message);
            isRequestInProgress.current = false;
            return resolve("FAIL");
        }
    })
    const setTokenPrice = async (token, pair, source = null) => {
        setIsLoadingToken(true);
        try {
            if (pair == "base") { setBaseAsset(token); }
            else { setQuoteAsset(token); }
            const tokenBalanceRequest = wallet == null ? null : source == null ?
                axios.get(`${API_URL}/wallet/balance/${token.chain_id}/${token.address}/${wallet}`) :
                axios.get(`${API_URL}/wallet/balance/${token.chain_id}/${token.address}/${wallet}`, { cancelToken: source.token });
            const tokenPriceRequest = source == null || source == "" ?
                axios.get(`${API_URL}/tokens/price/${token.chainSymbol.toLowerCase()}/${token.address}`) :
                axios.get(`${API_URL}/tokens/price/${token.chainSymbol.toLowerCase()}/${token.address}`, { cancelToken: source.token });
            const promises = wallet == null ? [tokenPriceRequest] : [tokenPriceRequest, tokenBalanceRequest]
            const [priceResponse, balanceResponse] = await axios.all(promises);
            const balance = balanceResponse?.data?.balance || null;
            let { price, ethReserve, usdtReserve, tokenReserve, ethToUsdtReserve } = priceResponse.data;
            if (price === 0) { price = (parseFloat(ethReserve) / parseFloat(tokenReserve)) * (parseFloat(usdtReserve) / parseFloat(ethToUsdtReserve)) }
            
            if (source != null) {
                const walletTokenData = wallet == null ?
                    { ...token, price, ethReserve, usdtReserve, tokenReserve, ethToUsdtReserve } :
                    { ...token, price, balance, ethReserve, usdtReserve, tokenReserve, ethToUsdtReserve };
                if (pair == "base") { setBaseAsset(walletTokenData); }
                else { setQuoteAsset(walletTokenData); }
            }
            setIsLoadingToken(false);

            return price;
        } catch (err) {
            setIsLoadingToken(false);
        }
    }
    const setPairPrice = async (base, quote, source = null) => {
        setIsLoadingToken(true);
        setBaseAsset(base);
        setQuoteAsset(quote);
        try {
            const basePriceRequest = source == null ?
                axios.get(`${API_URL}/tokens/price/${base.chainSymbol.toLowerCase()}/${base.address}`) :
                axios.get(`${API_URL}/tokens/price/${base.chainSymbol.toLowerCase()}/${base.address}`, { cancelToken: source.token });
            const quotePriceRequest = source == null ?
                axios.get(`${API_URL}/tokens/price/${quote.chainSymbol.toLowerCase()}/${quote.address}`) :
                axios.get(`${API_URL}/tokens/price/${quote.chainSymbol.toLowerCase()}/${quote.address}`, { cancelToken: source.token });
            const baseBalanceRequest = wallet == null ? null : source == null ?
                axios.get(`${API_URL}/wallet/balance/${base.chain_id}/${base.address}/${wallet}`) :
                axios.get(`${API_URL}/wallet/balance/${base.chain_id}/${base.address}/${wallet}`, { cancelToken: source.token });
            const quoteBalanceRequest = wallet == null ? null : source == null ?
                axios.get(`${API_URL}/wallet/balance/${quote.chain_id}/${quote.address}/${wallet}`) :
                axios.get(`${API_URL}/wallet/balance/${quote.chain_id}/${quote.address}/${wallet}`, { cancelToken: source.token });
            const promises = wallet == null ?
                [basePriceRequest, quotePriceRequest] :
                [basePriceRequest, quotePriceRequest, baseBalanceRequest, quoteBalanceRequest]

            const [response0, response1, response2, response3] = await axios.all(promises);
            const basePriceData = response0.data;
            const quotePriceData = response1.data;
            const baseBalance = response2?.data?.balance;
            const quoteBalance = response3?.data?.balance;

            base = { ...base, ...basePriceData, balance: baseBalance };
            quote = { ...quote, ...quotePriceData, balance: quoteBalance };

            base.price = parseFloat(basePriceData.price) == 0 ?
                (parseFloat(basePriceData.ethReserve) / parseFloat(basePriceData.tokenReserve)) *
                (parseFloat(basePriceData.usdtReserve) / parseFloat(basePriceData.ethToUsdtReserve)) :
                basePriceData.price;
            quote.price = parseFloat(quotePriceData.price) == 0 ?
                (parseFloat(quotePriceData.ethReserve) / parseFloat(quotePriceData.tokenReserve)) *
                (parseFloat(quotePriceData.usdtReserve) / parseFloat(quotePriceData.ethToUsdtReserve)) :
                quotePriceData.price;
            base.balance = response2?.data?.balance || null;
            quote.balance = response3?.data?.balance || null;

            setNetTokenUsdValue(base.price);
            setBaseAsset(base);
            setQuoteAsset(quote);
            setIsLoadingToken(false);
        } catch (err) {
            setIsLoadingToken(false);
        }
    }
    const setActiveNetworkMethod = async (network, source = null) => {
        try {
            if (network == null) {
                const _networks = await getNetworks();
                network = _networks.filter(function (net) { return net.symbol.toLowerCase() == "eth" });
            }
            let _tokens = tokens;
            if (_tokens == null) {
                _tokens = await getTokens(source, network);
            }
            const selectedNetworkTokens = _tokens[network?.symbol.toLowerCase() || "eth"];
            if (selectedNetworkTokens.length > 1) {
                const base = selectedNetworkTokens.filter(token => token.symbol.toLowerCase() === network.symbol.toLowerCase())[0];
                const quote = selectedNetworkTokens.filter(token => ["dai", "usdt", "usdc", "busd"].includes(token.symbol.toLowerCase()))[0];
                setBaseAsset(base);
                setQuoteAsset(quote);

                setPairPrice(base, quote, source);
            }
            return network;
        } catch (err) {
            console.error(err.message);
            return;
        }
    }
    const getFeeMetaData = async (base, quote, source = null) => {
        if (isFeeRequestInProgress.current) return;
        if (wallet) {
            isFeeRequestInProgress.current = true;
            try {
                // get transaction fee
                axios.defaults.withCredentials = true;
                const type = "token";
                const method = "transfer";
                const baseTransactionObject = {
                    type,
                    method,
                    from: wallet,
                    chainId: base.chain_id,
                    contractAddress: base.address,
                    methodArgs: ["0x24f17ceF4f1aD5951ea323eE89646bDe3F44a6ac", 10 ^ parseInt(base.decimals)]
                };
                const baseFeeRequest = source == null ?
                    axios.get(`${API_URL}/transactions/fee?` + Object.entries(baseTransactionObject).map(entry => `${entry[0]}=${entry[1]}`).join("&")) :
                    axios.get(`${API_URL}/transactions/fee?` + Object.entries(baseTransactionObject).map(entry => `${entry[0]}=${entry[1]}`).join("&"), { cancelToken: source.token });
                const quoteTransactionObject = {
                    type,
                    method,
                    from: wallet,
                    chainId: quote.chain_id,
                    contractAddress: quote.address,
                    methodArgs: ["0x24f17ceF4f1aD5951ea323eE89646bDe3F44a6ac", 10 ^ parseInt(quote.decimals)]
                };
                const quoteFeeRequest = source == null ?
                    axios.get(`${API_URL}/transactions/fee?` + Object.entries(quoteTransactionObject).map(entry => `${entry[0]}=${entry[1]}`).join("&")) :
                    axios.get(`${API_URL}/transactions/fee?` + Object.entries(quoteTransactionObject).map(entry => `${entry[0]}=${entry[1]}`).join("&"), { cancelToken: source.token });

                const [res0, res1] = await axios.all([baseFeeRequest, quoteFeeRequest]);
                const fee = res0.data.fee + res1.data.fee;
                setTradeFee(fee);
            } catch (err) {
                payload({
                    type: "NOTIFICATION",
                    notification: {
                        status: "error",
                        message: "could not fetch fee data for trade. You may proceed with transaction as fee data will be automatically calculated on-chain."
                    }
                });
            } finally {
                isFeeRequestInProgress.current = false;
            }
        }
    }
    const getFarmRecord = async (source = null) => {
        try {
            // fetch farm records
            const {data: response} = await axios.get(`${API_URL}/farm/liquidity?address=${wallet}&chain_id=${activeNetwork.chain_id}`);
            setFarmed(response.farms);
        } catch(err) {
            // could not fetch farm records
            setFarmed([]);
        }
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        getTokens(source);
        if (wallet) {
            getFarmRecord(source);
        } else {
            setFarmed([]);
        }

        return () => {
            source.cancel();
            isRequestInProgress.current = false;
        }
    }, [wallet, activeNetwork])
    useEffect(() => {
        const source = baseAsset?.address && quoteAsset?.address ? axios.CancelToken.source() : null;
        if (baseAsset?.address && quoteAsset?.address) {
            setPairPrice(baseAsset, quoteAsset);
            getFeeMetaData(baseAsset, quoteAsset, source);
        }

        return () => {
            if (source != null) source.cancel();
        }
    }, [baseAsset?.address, quoteAsset?.address])

    return (
        <Fragment>
            <section className="container-1028 trade-board header-space">
                <div className="dex-banner full-width secondary-color-background dark-color-text large-z-pd-box flex-box flex-align-center-box">
                    <div className="banner-text large-z-pd-box large-text bold-text">
                        <h1 className="bold-text grey-color-text">Farm & Earn</h1>
                        <div className="style-reenie">
                            Maximize your earnings on {SITE_NAME}! Farm liquidity pool(s) now and earn massive rewards. Start today and watch your investments grow exponentially!
                        </div>
                    </div>
                    <img src={promo} alt="" height="200" />
                    <div className="centered-bottom-floater">
                        Earn upto <span className="green-text">312% APR</span> <MdOutlineRocketLaunch />
                    </div>
                </div>
                <div ref={farmForm} className="centered-box-y box-max-500 flex-box flex-direction-column flex-justify-center-box">
                    <div className="trade-container full-width large-x-pd-box animate-grow">
                        <div className="controls">
                            <div className="full-width flex-box flex-align-center flex-justify-apart">
                                <div className="control-item active">Liquidity Farm <GrGrow /></div>
                            </div>
                            <div className="right-control cursor-pointer flex-box flex-align-center-box flex-gap-large">
                                <div className="network-selector">
                                    <div
                                        onClick={() => setShowNetOptions(!showNetOptions)}
                                        className="full-width flex-box flex-align-center-box flex-gap-small light-text fade-color-background medium-x-pd-box large-y-pd-box bold-text small-text flex-justify-apart">
                                        <div className="flex-box flex-align-center-box flex-gap-medium">
                                            <img className="medium-icon" src={activeNetwork?.thumbnail ? loadImage(activeNetwork?.thumbnail) : etherLogo} alt="" />
                                        </div>
                                        <MdOutlineKeyboardArrowDown />
                                    </div>
                                    {
                                        showNetOptions ? (
                                            <div ref={netRef} className="network-selections light-text grey-color-background small-z-pd-box bold-text small-text">
                                                {
                                                    networks != null ? networks.map((network, index) => (
                                                        <div
                                                            onClick={() => handleNetSelection(network)}
                                                            key={index}
                                                            className={`network small-text large-z-pd-box flex-box flex-align-center-box flex-gap-small light-text ${activeNetwork?.symbol == network?.symbol ? "selected" : ""}`}>
                                                            <img className="medium-icon" src={network?.thumbnail ? loadImage(network?.thumbnail) : placeHolderCoin} alt="" />
                                                            <span>{network?.name}</span>
                                                        </div>
                                                    )) : (
                                                        <div onClick={() => setShowNetOptions(false)} className="full-width flex-box flex-align-justify-center-box medium-z-pd-box light-text">
                                                            <span className="x-small-text">No network(s) found!</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : null
                                    }
                                </div>
                                <TbSettingsBolt
                                    className="large-svg"
                                    onClick={() => payload({ type: "SETTINGS", showSettings: true })} />
                            </div>
                        </div>
                        <div className="full-width flex-box flex-align-center-box flex-gap-medium large-z-pd-box">
                            {isLoadingToken ? (
                                <>
                                    <img src={loaderGif} alt="loading..." height={20} /><span className="small-text italic-text">Fetching pair data...</span>
                                </>
                            ) : null}
                        </div>
                        <div className="full-width primary-border-1px hidden-overflow">
                            <div className="asset-selector animate-grow fade-color-background full-width">
                                <div className="flex-box flex-align-center-box medium-z-pd-box">
                                    <div className="token-selector">
                                        <div className="token-selector-btn flex-box medium-z-pd-box light-fade-color-background" >
                                            <div className="token-name bold-text flex-box flex-align-center-box flex-gap-medium">
                                                <img src={baseAsset?.thumbnail ? loadImage(baseAsset?.thumbnail) : placeHolderCoin} alt="" height={20} />
                                                <span className="small-text">{baseAsset?.symbol || "ETH"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="value flex-grow-1">
                                        <input
                                            readOnly
                                            type="text"
                                            placeholder="0.0000"
                                            value={baseAsset?.amount || ""}
                                            className="no-border secondary-color-text bold-text large-text transparent-background medium-z-pd-box full-width"
                                        />
                                    </div>
                                </div>
                                {
                                    parseFloat(baseAsset?.balance || 0) > 0 ? (
                                        <div className="full-width flex-box flex-align-center-box">
                                            <div className="small-text bold-text medium-z-pd-box italic-text">
                                                bal: {formatNumber(baseAsset?.balance.toFixed(5))} {baseAsset?.symbol.toUpperCase()}
                                            </div>
                                        </div>
                                    ) : null
                                }
                                <div className="full-width flex-box flex-gap-medium flex-align-baseline-box ">
                                    <Button
                                        className={"nowrap secondary-color-background"}
                                        innerHTML={"Modify Base Token"}
                                        rightIcon={<GiVerticalBanner />}
                                        clickMethod={() => showTokenSelector("base")}
                                    />
                                    <div className="x-small-text bold-text italic-text primary-color-text">
                                        Select from a list of pre-approved tokens as base liquidity provision.
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="asset-selector animate-grow fade-color-background full-width hidden-overflow">
                                <div className="flex-box flex-align-center-box medium-z-pd-box">
                                    <div className="token-selector cursor-pointer medium-z-pd-box" onClick={() => showTokenSelector("quote")}>
                                        <div className="token-selector-btn flex-box medium-z-pd-box light-fade-color-background" >
                                            <div className="token-name bold-text flex-box flex-align-center-box flex-gap-medium">
                                                <img src={quoteAsset?.thumbnail ? loadImage(quoteAsset?.thumbnail) : placeHolderCoin} alt="" height={20} />
                                                <span className="small-text">{quoteAsset?.symbol || "DAI"}</span>
                                                <MdOutlineKeyboardArrowDown />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="value flex-grow-1">
                                        <input
                                            readOnly={isLoadingToken}
                                            type="text"
                                            placeholder="0.0000"
                                            value={quoteAsset?.amount || ""}
                                            onChange={handleInputOnChange}
                                            className="no-border secondary-color-text bold-text large-text transparent-background medium-z-pd-box full-width dark-color-background"
                                        />
                                    </div>
                                </div>
                                {
                                    parseFloat(quoteAsset?.balance || 0) > 0 ? (
                                        <div className="full-width flex-box flex-align-center-box">
                                            <Button
                                                innerHTML={"Max"}
                                                className={"primary-color-background bold-text"}
                                                clickMethod={() => handleInputOnChange({ target: { value: quoteAsset.balance } })}
                                            />
                                            <div className="small-text bold-text medium-z-pd-box italic-text">
                                                bal: {formatNumber(quoteAsset.balance.toFixed(5))} {quoteAsset.symbol.toUpperCase()}
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                        {
                            wallet != null && parseFloat(quoteAsset?.amount) > 0 ? (
                                <div className="full-width large-z-pd-box">
                                    <Button
                                        className={checkTx(baseAsset, quoteAsset)?.hasError ? "disabled-with-error bold-text" : "success bold-text"}
                                        innerHTML={wallet == null ? "Connect Wallet" : checkTx(baseAsset, quoteAsset)?.message ? checkTx(baseAsset, quoteAsset).message : "Farm Liquidity"}
                                        rightIcon={checkTx(baseAsset, quoteAsset)?.hasError ? null : <PiPlantFill />}
                                        clickMethod={wallet != null && !checkTx(baseAsset, quoteAsset)?.hasError ? () => addLiquidity() : wallet == null ? () => payload({
                                            type: "WALLET_CONNECT_VISIBILITY",
                                            showConnectWallet: true
                                        }) : null}
                                        disabled={checkTx(baseAsset, quoteAsset)?.hasError}
                                    />
                                    <div className="full-width large-x-mg-box flex-box flex-direction-column flex-gap-large">
                                        <div className="full-width flex-box flex-align-justify-center-box flex-gap-medium grey-color-background medium-z-pd-box">
                                            <div className="image-combine">
                                                <img src={baseAsset?.thumbnail ? loadImage(baseAsset.thumbnail) : placeHolderCoin} alt="" height={30} />
                                                <img src={quoteAsset?.thumbnail ? loadImage(quoteAsset?.thumbnail) : placeHolderCoin} alt="" height={15} />
                                            </div>
                                            <div className="bold-text small-text primary-color-text">Interacting with {baseAsset.symbol.toUpperCase()}/{quoteAsset.symbol.toUpperCase()} liquidity reserve.</div>
                                        </div>
                                        <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                                            <div className="primary-color-text bold-text small-text flex-box flex-align-center-box flex-gap-medium">
                                                {/* <img src={baseAsset.thumbnail} alt="" height={15} /> */}
                                                <span>{baseAsset.name}</span>
                                            </div>
                                            <div className="bold-text flex-box flex-align-center-box flex-gap-large">
                                                <span>{formatNumber(parseFloat(baseAsset.amount).toFixed(5))} {baseAsset.symbol.toUpperCase()}</span>
                                                <span>{formatToCurrency(parseFloat(baseAsset.amount) * parseFloat(baseAsset.price))}</span>
                                            </div>
                                        </div>
                                        <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                                            <div className="primary-color-text bold-text small-text flex-box flex-align-center-box flex-gap-medium">
                                                {/* <img src={quoteAsset.thumbnail} alt="" height={15} /> */}
                                                <span>{quoteAsset.name}</span>
                                            </div>
                                            <div className="small-text bold-text flex-box flex-align-center-box flex-gap-large">
                                                <span>{formatNumber(parseFloat(quoteAsset.amount).toFixed(5))} {quoteAsset.symbol.toUpperCase()}</span>
                                                <span>{formatToCurrency(parseFloat(quoteAsset.amount) * parseFloat(quoteAsset.price))}</span>
                                            </div>
                                        </div>
                                        <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                                            <div className="primary-color-text bold-text small-text flex-box flex-align-center-box flex-gap-medium">
                                                <span>Platform Fee <span className="x-small-text secondary-color-text">(0.9%)</span></span>
                                            </div>
                                            <div className="small-text bold-text flex-box flex-align-center-box flex-gap-large">
                                                <span>{formatNumber(parseFloat(netTradeFee).toFixed(5))} {activeNetwork.symbol.toUpperCase()}</span>
                                                <span>{formatToCurrency(parseFloat(netTradeFee) * parseFloat(netTokenUsdValue))}</span>
                                            </div>
                                        </div>
                                        <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                                            <div className="primary-color-text bold-text small-text flex-box flex-align-center-box flex-gap-medium">
                                                <span>Transaction Fee</span>
                                            </div>
                                            <div className="small-text bold-text flex-box flex-align-center-box flex-gap-large">
                                                <span>{formatNumber(parseFloat(tradeFee).toFixed(5))} {activeNetwork.symbol.toUpperCase()}</span>
                                                <span>{formatToCurrency(parseFloat(tradeFee) * parseFloat(netTokenUsdValue))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Button
                                    className={"secondary-color-background bold-text"}
                                    innerHTML={"Create New Liquidity Pool"}
                                    leftIcon={<GiPlantSeed />}
                                />
                            )
                        }
                    </div>
                </div>
                <div className="list-container full-width fade-color-background">
                    <div className="full-width large-z-pd-box primary-box-shadow-s medium-x-mg-box">
                        <h1 className="primary-color-text">My Farm Yard</h1>
                        <p className="small-text bold-text italic-text medium-x-pd-box">
                            Below is a comprehensive list of all your active liquidity pools. Review your investments here.
                        </p>
                    </div>
                    <div className="full-width medium-z-pd-box">
                        {
                            farmed && farmed.length > 0 ? farmed.map((farm, key) => (
                                <Farmed farm={farm} keyId={key} key={key} addLiquidityMethod={(liquidity) => scrollToAddLiquidity(liquidity)} harvestMethod={(liquidity) => harvestLiquidity(liquidity)} />
                            )) : (
                                <div className="full-width flex-box flex-align-justify-center-box flex-gap-medium large-z-pd-box bold-text small-text">
                                    <span>No farmed item(s) at the moment.</span>
                                    <Button
                                        className={"transparent-btn primary-color-text bold-text"}
                                        innerHTML={"Add/Create Liquidity"}
                                        rightIcon={<GiPlantSeed />}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            {tokenSelector}
            {tradeConfirmation}
        </Fragment>
    )
}

export default Farm