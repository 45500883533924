import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useStateValue } from "../Context/StateProvider";
import { MdOutlineClose, MdKeyboardBackspace, MdArrowForward, MdOutlineCheck } from "react-icons/md";
import { AiOutlineImport } from "react-icons/ai";
import { IoWallet } from "react-icons/io5";

import logo from "../Contents/images/logo.png";
import Button from "./Button";
import CopyText from "./CopyText";

const CreateWallet = ({setCurrentDisplay}) => {
    const [{API_URL}] = useStateValue();
    const [seed, setSeed] = useState("");
    const [loadingSeed, setLoadingSeed] = useState(false);

    const getNewSeed = async () => {
        setLoadingSeed(true)
        try {
            const response =  await axios(`${API_URL}/wallet/new`);
            const newSeed = response.data.wallet
            const preparedSeed = newSeed.split(" ").map((word, index) => <span key={index}>{index + 1}. {word}</span>);
            
            setSeed(
                <Fragment>
                    <div className="seed">{preparedSeed}</div>
                    <CopyText 
                        copyString={newSeed}
                        innerHTML="Copy Phrase"
                    />
                    <div className="container-footer small-text">
                        You may want to write your phrase down. 
                    </div>
                </Fragment>
            );
        } catch(err) {
            setSeed(<div className="full-width centered-text small-text bold-text grey-color-text">Error fetching new Mnemonic Seed Phrase.<br/>Please try again</div>)
        } finally {
            setLoadingSeed(false)
        }
    }

    useEffect(() => {
        getNewSeed();
    }, [])
    return (
        loadingSeed ? (
            <div className="modal-content">
                Loading...
            </div>
        ) : (
            <div className="modal-content">
                <div className="logo-container">
                    <img src={logo} alt="" />
                </div>
                <div className="seed-container">
                    {seed}
                </div>
                <div className="options">
                    <Button 
                        className="transparent-btn return-btn"
                        innerHTML="Back"
                        clickMethod={() => setCurrentDisplay(<ConnectOptions setCurrentDisplay={setCurrentDisplay}  />)}
                        leftIcon={<MdKeyboardBackspace />}
                    />
                    <Button 
                        className="primary-color-background"
                        innerHTML="Next"
                        clickMethod={() => setCurrentDisplay(<ImportWallet setCurrentDisplay={setCurrentDisplay}  />)}
                        rightIcon={<MdArrowForward />}
                    />
                </div>
                <div className="disclaimer small-text">
                    <div className="row">
                        You are solely responsible for the security and backup of your seed phrase. Loss of this phrase cannot be recovered, and access to your wallet will be permanently lost if the phrase is not securely stored.
                    </div>
                    <div className="row">
                        We do not store your seed phrase and therefore cannot assist in recovering your wallet if the seed phrase is lost or forgotten. There is no way to retrieve your funds without it.
                    </div>
                    <div className="row">
                        Securing your seed phrase against unauthorized access is crucial. Exposure can lead to theft of funds. Always keep it private and secure.
                    </div>
                </div>
            </div>
        )
    )
}

const PassCode = ({authData, setCurrentDisplay}) => {
    const [{API_URL}, payload] = useStateValue()
    const [passCode, setPassCode] = useState("");
    const [isDisabled, setIsDisabled] = useState(true)

    const importWallet = async () => {
        payload({
            type: "FETCHING",
            fetching: true
        });
        try {
            axios.defaults.withCredentials = true;
            const response = await axios.post(`${API_URL}/wallet`, {...authData, passCode});
            const fetchedWallet = response.data;
            payload({
                type: "WALLET_CONNECT_VISIBILITY",
                showConnectWallet: false
            })
            setTimeout(() => {
                payload({
                    type: "BEARER",
                    bearer: fetchedWallet.token
                });
                payload({
                    type: "WALLET",
                    wallet: fetchedWallet.address
                })
                payload({
                    type: "NOTIFICATION",
                    notification: { status: "success", message: "Wallet Connected"}
                })
            }, 550)
        } catch(err) {
            payload({
                type: "NOTIFICATION",
                notification: { status: "error", message: err.message || "Unknown error"}
            })
        } finally {
            payload({
                type: "FETCHING",
                fetching: false
            });
        }
    }
    const closeWalletConnect = () => {
        payload({
            type: "WALLET_CONNECT_VISIBILITY",
            showConnectWallet: false
        });
    }

    useEffect(() => {
        if (passCode.toString().length >= 6 && passCode.toString().length <= 64) {
            setIsDisabled(false);
        }else {
            setIsDisabled(true);
        }
    }, [passCode])

    return (
        <div className="modal-content">
            <div className="form-content">
                <div className="full-width flex-box flex-justify-center-box flex-direction-column flex-gap-medium medium-x-mg-box">
                    <strong className="medium-text secondary-color-text">Provide extra security for your asset(s).</strong>
                    <span className="small-text bold-text grey-color-text">Use a minimum of six (6) and a maximum of 50 character to secure your wallet and approve transactions.</span>
                </div>
                <div className="private-key-input">
                    <input 
                        maxLength={64}
                        minLength={6}
                        className="centered-text pass-code"
                        type="password"
                        placeholder="Pass code"
                        value={passCode}
                        onChange={(e) => setPassCode(e.target.value)} />
                </div>
            </div>
            <div className="options">
                <Button 
                    className="transparent-btn return-btn"
                    innerHTML="Cancel"
                    clickMethod={closeWalletConnect}
                    leftIcon={<MdKeyboardBackspace />}
                />
                <Button 
                    className="primary-color-background"
                    innerHTML="Import Wallet"
                    clickMethod={importWallet}
                    disabled={isDisabled}
                    leftIcon={<AiOutlineImport />}
                />
            </div>
        </div>
    )
}

const ImportWallet = ({setCurrentDisplay}) => {
    const [authType, setAuthType] = useState("seed");
    const [authData, setAuthData] = useState({});
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        let _shouldBeDisabled = true;
        if (authType == "seed") {
            if (authData.seed && [12, 24].includes(authData.seed.trim().split(" ").length)) {
                _shouldBeDisabled = false;
            }
        }
        if (authType == "private-key") {
            if (authData.privateKey && authData.privateKey.length === 64) {
                _shouldBeDisabled = false;
            }
        }
        if (authType == "json-key") {
            if (authData.json != null && typeof(authData.json) == "object" && authData?.password != "") {
                _shouldBeDisabled = false;
            }
        }
        setIsDisabled(_shouldBeDisabled);
    }, [authData])
    useEffect(() => {
        setAuthData({})
    }, [authType])
    return (
        <div className="modal-content">
            <div className="logo-container">
                <img src={logo} alt="" />
            </div>
            <div className="controls">
                <div onClick={() => setAuthType("seed")} className={`control-item bold-text small-text secondary-color-text ${authType === 'seed' ? 'active' : ''}`}>Seed Phrase</div>
                <div onClick={() => setAuthType("private-key")} className={`control-item bold-text small-text secondary-color-text ${authType === 'private-key' ? 'active' : ''}`}>Private Key</div>
                <div onClick={() => setAuthType("json-key")} className={`control-item bold-text small-text secondary-color-text ${authType === 'json-key' ? 'active' : ''}`}>JSON Key Pair</div>
            </div>
            <div className="form-content">
            {
                authType === "seed" ? (
                    <div className="seed-input">
                        <textarea 
                            placeholder="Mnemonic Seed Phrase"
                            value={authData.seed || ""}
                            onChange={(e) => setAuthData({...authData, seed: e.target.value})}></textarea>
                    </div>
                ) : authType === "private-key" ? (
                    <div className="private-key-input">
                        <input 
                            type="text"
                            placeholder="Private Key"
                            value={authData.privateKey || ""}
                            onChange={(e) => setAuthData({...authData, privateKey: e.target.value})} />
                    </div>
                ) : authType === "json-key" ? (
                    <div className="json-key-input">
                        <input 
                            type="text"
                            placeholder="Password"
                            value={authData.password || ""}
                            onChange={(e) => setAuthData({...authData, password: e.target.value})} />
                        <textarea
                            placeholder="JSON"
                            value={authData.json || ""}
                            onChange={(e) => setAuthData({...authData, json: e.target.value})}></textarea>
                    </div>
                ) : null
            }
            </div>
            <div className="options">
                <Button 
                    className="transparent-btn return-btn"
                    innerHTML="Back"
                    clickMethod={() => setCurrentDisplay(<ConnectOptions setCurrentDisplay={setCurrentDisplay}  />)}
                    leftIcon={<MdKeyboardBackspace />}
                />
                <Button 
                    className="primary-color-background"
                    innerHTML="Continue"
                    clickMethod={() => setCurrentDisplay(<PassCode setCurrentDisplay={setCurrentDisplay} authData={authData}  />)}
                    disabled={isDisabled}
                    leftIcon={<MdOutlineCheck />}
                />
            </div>
        </div>
    )
}

const ConnectOptions = ({setCurrentDisplay}) => {
    return (
        <div className="modal-content">
            <div className="illustration">
                {/* <img src={techface} alt="" /> */}
            </div>
            <div className="large-text bold-text centered-text">
                Connect your wallet with confidence — security is our priority. Safeguard your crypto journey with us today!
            </div>
            <div className="side-bar small-text">
                Buy, Sell, Swap and Stake 500+ crypto assets <br/>
                Multi-Chain & NFT support<br/>
                Browser native, no risky extensions
            </div>
            <div className="options">
                {/* create wallet */}
                <Button 
                    className="primary-color-background bold-text"
                    innerHTML="Create New Wallet"
                    leftIcon={<IoWallet />}
                    clickMethod={() => setCurrentDisplay(<CreateWallet setCurrentDisplay={setCurrentDisplay} />)}
                />
                {/* i already have a wallet */}
                <Button 
                    className="secondary-color-background bold-text"
                    innerHTML="Import Existing Wallet"
                    leftIcon={<AiOutlineImport />}
                    clickMethod={() => setCurrentDisplay(<ImportWallet setCurrentDisplay={setCurrentDisplay} />)}
                />
            </div>
        </div>
    )
}

const ConnectWallet = () => {
    const [{showConnectWallet}, payload] = useStateValue();
    const [currentDisplay, setCurrentDisplay] = useState("");

    const closeWalletConnect = () => {
        payload({
            type: "WALLET_CONNECT_VISIBILITY",
            showConnectWallet: false
        });
    }

    useEffect(() => {
        setCurrentDisplay(<ConnectOptions setCurrentDisplay={setCurrentDisplay} />);
    }, [showConnectWallet])

    return (
        showConnectWallet ? (
            <div className="backdrop">
                <div className="modal">
                    <div className="modal-header flex-box flex-align-center-box flex-justify-apart">
                        <div>
                            <div className="bold-text secondary-color-text">Import Wallet</div>
                            <div className="small-text grey-color-text">Import your wallet to interact with the DEX</div>
                        </div>
                        <MdOutlineClose onClick={closeWalletConnect} />
                    </div>
                    <div className="modal-body">{currentDisplay}</div>
                </div>
            </div>
        ) : null
    )
}

export default ConnectWallet