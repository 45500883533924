import React from "react";
import Button from "./Button";
import { copyContent } from "../Context/general";
import { useStateValue } from "../Context/StateProvider";
import { RiFileCopyLine } from "react-icons/ri";

const CopyText = ({copyString = null, innerHTML, className, leftIcon}) => {
    const [_, payload] = useStateValue();

    const copyText = async () => {
        try {
            await copyContent(copyString || innerHTML);
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "success",
                    message: "copied!"
                }
            });
        } catch(err) {
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "error",
                    message: "failed to copy!"
                }
            });
        }
    }
  return (
    <Button 
        leftIcon={leftIcon}
        rightIcon={<RiFileCopyLine />}
        className={`transparent-btn ${className || ""} primary-color-text bold-text`}
        innerHTML={innerHTML}
        clickMethod={copyText}
    />
  )
}

export default CopyText