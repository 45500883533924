import React, { Fragment } from "react";
import { useStateValue } from "../Context/StateProvider";
import ToggleButton from "./ToggleButton";

import { MdOutlineClose } from "react-icons/md";
import { PiWarningDiamondFill } from "react-icons/pi";
import { sanitizeToNumber } from "../Context/general";

const Settings = () => {
    const [{ showSettings, slippage, deadline }, payload] = useStateValue();

    const setSlippage = (e) => {
        let newSlippage = e.target.value;
        if (newSlippage !== "auto") {
            if (isNaN(newSlippage)) {
                newSlippage = sanitizeToNumber(newSlippage);
            }
            if (parseFloat(newSlippage) < 0) {
                newSlippage = 1;
            }
            if (parseFloat(newSlippage) > 99) {
                newSlippage = "auto";
            }
        }
        payload({ type: "SLIPPAGE", slippage: newSlippage });
    }
    const setDeadline = (e) => {
        let newDeadline = e.target.value;
        if (isNaN(newDeadline)) {
            newDeadline = sanitizeToNumber(newDeadline);
        }
        if (parseFloat(newDeadline) < 0) {
            newDeadline = 10;
        }
        payload({ type: "DEADLINE", deadline: newDeadline });
    }
    return (
        showSettings ? (
            <div className="backdrop">
                <div className="modal box-450">
                    <div className="modal-header flex-box flex-align-center-box flex-justify-apart">
                        <div>
                            <div className="medium-text bold-text secondary-color-text">Settings</div>
                            <div className="small-text grey-color-text">Adjust tools to your personal preference.</div>
                        </div>
                        <MdOutlineClose onClick={() => payload({ type: "SETTINGS", showSettings: false })} />
                    </div>
                    <div className="modal-body large-z-pd-box animate-grow flex-box flex-gap-large flex-direction-column">
                        <div className="full-width grey-color-background large-z-pd-box">
                            <div className="flex-box flex-justify-apart medium-z-pd-box">
                                <div>
                                    <div className="small-text bold-text">Automatic Slippage Tolerance</div>
                                    <div className="small-text">
                                        Turn off automatic slippage tolerance to adjust the value.
                                    </div>
                                </div>
                                <ToggleButton
                                    propState={slippage === "auto"}
                                    changePropState={() => payload({ type: "SLIPPAGE", slippage: slippage === "auto" ? 1 : "auto" })} />
                            </div>
                            {
                                slippage != "auto" ? (
                                    <Fragment>
                                        <div className="medium-z-pd-box">
                                            {/* <hr /> */}
                                            <div className="hr-line-small-fade"></div>
                                        </div>
                                        <div className="flex-box flex-align-center-box flex-justify-apart medium-z-pd-box">
                                            <div className="small-text bold-text flex-box flex-align-center-box flex-gap-medium">
                                                <span>Slippage</span>
                                                <PiWarningDiamondFill className="cursor-pointer" />
                                            </div>
                                            <div className="small-text">
                                                {slippage}%
                                            </div>
                                        </div>
                                        <div className="flex-box flex-align-center-box flex-justify-apart medium-z-pd-box">
                                            <div className="full-width fade-border-small flex-box flex-align-center-box flex-justify-apart small-z-pd-box">
                                                <div className="flex-box flex-align-center-box flex-justify-apart flex-gap-large">
                                                    <div
                                                        onClick={() => payload({ type: "SLIPPAGE", slippage: 0.1 })}
                                                        className="hover-fade-background fade-border-small cursor-pointer small-text bold-text small-z-pd-box square-2x flex-box flex-align-justify-center-box">0.1%</div>
                                                    <div
                                                        onClick={() => payload({ type: "SLIPPAGE", slippage: 0.5 })}
                                                        className="hover-fade-background fade-border-small cursor-pointer small-text bold-text small-z-pd-box square-2x flex-box flex-align-justify-center-box">0.5%</div>
                                                    <div
                                                        onClick={() => payload({ type: "SLIPPAGE", slippage: 1 })}
                                                        className="hover-fade-background fade-border-small cursor-pointer small-text bold-text small-z-pd-box square-2x flex-box flex-align-justify-center-box">1%</div>
                                                </div>
                                                <div className="slippage-form">
                                                    <input 
                                                        type="text" 
                                                        value={slippage} 
                                                        onChange={setSlippage}
                                                        onBlur={slippage === "" ? () => payload({type: "SLIPPAGE", slippage: 1}) : null} />
                                                    <label htmlFor="slippage">%</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : null
                            }
                        </div>
                        <div className="full-width grey-color-background large-z-pd-box">
                            <div className="flex-box flex-align-center-box flex-justify-apart medium-z-pd-box">
                                <div className="small-text bold-text flex-box flex-align-center-box flex-gap-medium">
                                    <span>Transaction Deadline</span>
                                    <PiWarningDiamondFill className="cursor-pointer" />
                                </div>
                                <div className="small-text">
                                    {deadline} Min
                                </div>
                            </div>
                            <div className="flex-box flex-align-center-box flex-justify-apart medium-z-pd-box">
                                <div className="full-width fade-border-small flex-box flex-align-center-box flex-justify-apart small-z-pd-box">
                                    <div className="flex-box flex-align-center-box flex-justify-apart flex-gap-large">
                                        <div
                                            onClick={() => payload({ type: "DEADLINE", deadline: 5 })}
                                            className="hover-fade-background fade-border-small cursor-pointer small-text bold-text small-z-pd-box square-2x flex-box flex-align-justify-center-box">5 Min</div>
                                        <div
                                            onClick={() => payload({ type: "DEADLINE", deadline: 10 })}
                                            className="hover-fade-background fade-border-small cursor-pointer small-text bold-text small-z-pd-box square-2x flex-box flex-align-justify-center-box">10 Min</div>
                                        <div
                                            onClick={() => payload({ type: "DEADLINE", deadline: 15 })}
                                            className="hover-fade-background fade-border-small cursor-pointer small-text bold-text small-z-pd-box square-2x flex-box flex-align-justify-center-box">15 Min</div>
                                    </div>
                                    <div className="slippage-form">
                                        <input 
                                            type="text" 
                                            value={deadline} 
                                            onChange={setDeadline}
                                            onBlur={deadline === "" ? () => payload({type: "DEADLINE", deadline: 10}) : null} />
                                        <label htmlFor="slippage">Min</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="full-width"></div>
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default Settings