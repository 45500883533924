import React from "react";

const PoolsBlock = ({ pools }) => {
    return (
        <div>
        {
            pools.length > 0 ? (
                <div className="full-width">
                {
                    pools?.map((pool) => (
                        <div className="full-width large-z-pd-box">{pool?.name}</div>
                    ))
                }
                </div>
            ) : (
                <div className="full-width flex-box flex-align-justify-center-box">No pool item(s) found.</div>
            )
        }
        </div>
    )
}

export default PoolsBlock