import React, { useEffect, useState } from "react";
import { formatNumber, formatToCurrency, loadImage } from "../Context/general";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import placeHolderCoin from "../Contents/images/favicon.png";
import loader from "../Contents/images/loader.gif";
const portionClass = "cursor-pointer small-text bold-text flex-box flex-align-center-box flex-gap-small medium-y-pd-box small-x-pd-box border-box border-secondary-color";

const AssetManager = ({ onChange, asset, assetCategory, openTokenSelector, loadingToken, isReadOnly }) => {
    const [tokenIcon, setTokenIcon] = useState(null);

    const displayIcon = async () => {
        try {
            const imageSrc = asset?.thumbnail != null ? await loadImage(asset?.thumbnail) : placeHolderCoin;
            setTokenIcon(imageSrc);
        } catch(err) {}
    }
    const setValue = (share) => {
        if (loadingToken) return;
        onChange({target: {value: parseFloat(asset?.balance) * (share/100), dataset: {category: assetCategory}}});
    }
    useEffect(() => {
        displayIcon();
    }, [asset?.thumbnail])
    return (
        <div className="asset-selector animate-grow">
            <div>
                <div className="value">
                    <input className="no-border light-color-text bold-text" data-category={assetCategory} type="text" placeholder="0.0000" value={asset?.amount == "NaN" ? "" : asset?.amount} onChange={onChange} readOnly={isReadOnly} />
                </div>
                <div className="token-selector flex-box">
                    <div className="token-selector-btn" onClick={loadingToken ? () => null : openTokenSelector}>
                        <img src={tokenIcon || placeHolderCoin} alt="" />
                        <div className="token-name bold-text flex-box flex-align-center-box flex-gap-medium">
                            <span className="small-text">{asset?.symbol}</span>
                            <MdOutlineKeyboardArrowDown />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="usd-value">{formatToCurrency(parseFloat(asset?.amount || 0) * parseFloat(asset?.price || 0), "usd")}</div>
                <div className="wallet-balance">
                    <IoWalletOutline />
                    <span>{loadingToken ? <img scr={loader} alt="loading..." height={10} /> : asset?.balance ? formatNumber(parseFloat(asset?.balance).toFixed(5) ) : "0.0000"}</span>
                </div>
            </div>
            {
                assetCategory == "base" && asset?.balance > 0 ? (
                    <div className="auto-fill flex-box flex-align-center-box flex-gap-small">
                        <div 
                            className={parseFloat(asset.amount) === parseFloat(asset.balance) * (25/100) ? `${portionClass} secondary-color-background` : portionClass} onClick={() => setValue(25)}>25%</div>
                        <div 
                            className={parseFloat(asset.amount) === parseFloat(asset.balance) * (50/100) ? `${portionClass} secondary-color-background` : portionClass} onClick={() => setValue(50)}>50%</div>
                        <div 
                            className={parseFloat(asset.amount) === parseFloat(asset.balance) * (75/100) ? `${portionClass} secondary-color-background` : portionClass} onClick={() => setValue(75)}>75%</div>
                        <div 
                            className={parseFloat(asset.amount) === parseFloat(asset.balance) ? `${portionClass} secondary-color-background` : portionClass} onClick={() => setValue(100)}>MAX</div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default AssetManager