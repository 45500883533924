import React, { useEffect, useState } from 'react'

const ToggleButton = ({changePropState = () => null, propState = false}) => {
    const [isActive, setIsActive] = useState(null);

    const toggleKnob = () => {
        setIsActive(!isActive);
        changePropState(!isActive);
    }

    useEffect(() => {
        if (propState != isActive) {
            setIsActive(propState);
        }
    }, [propState])

    return (
        <div className="toggle-btn">
            <div
                onClick={toggleKnob}
                className={`toggle-rail flex-box flex-align-center-box ${isActive ? "active" : ""}`}>
                <div className="toggle-knob"></div>
            </div>
        </div>
    )
}

export default ToggleButton