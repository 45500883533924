import React, { useEffect, useState } from "react";
import { useStateValue } from "../Context/StateProvider";
import { MdOutlineClose, MdOutlineDangerous } from "react-icons/md";
import Button from "./Button";
import { IoMdClose } from "react-icons/io";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import loaderGif from "../Contents/images/loader.gif";
import axios from "axios";
import { formatNumber, loadImage } from "../Context/general";
import placeHolderCoin from "../Contents/images/placeholder-coin.png";
import ConfirmStake from "./ConfirmStake";

const PoolForm = () => {
    const [{ API_URL, showPoolForm, poolData, wallet, activeNetwork }, payload] = useStateValue();
    const [tokenBalance, setTokenBalance] = useState(0);
    const [tokenPrice, setTokenPrice] = useState(0);
    const [fee, setFee] = useState(0);
    const [stakeAmount, setStakeAmount] = useState("");
    const [lockPeriod, setLockPeriod] = useState("");
    const [error, setError] = useState(null);
    const [pool, setPool] = useState(null);
    const [earnIcon, setEarnIcon] = useState(null);
    const [stakeIcon, setStakeIcon] = useState(null);
    const [showLockMessage, setShowLockMessage] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [loadingToken, setLoadingToken] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [stakeConfirmation, setStakeConfirmation] = useState(null);
    const [stakeFee, setStakeFee] = useState(0);


    const displayIcon = async () => {
        setLoadingImage(true);
        try {
            const earnThumbnail = poolData?.earn_thumbnail ? await loadImage(poolData?.earn_thumbnail) : placeHolderCoin;
            const stakeThumbnail = poolData?.stake_thumbnail ? await loadImage(poolData?.stake_thumbnail) : placeHolderCoin;
            setEarnIcon(earnThumbnail);
            setStakeIcon(stakeThumbnail);
        } catch (err) {
            setEarnIcon(placeHolderCoin);
            setStakeIcon(placeHolderCoin);
        } finally {
            setLoadingImage(false);
        }
    }
    const handleInputOnChange = (e) => {
        let value = e.target.value;
        if (value != "") {
            value = value.toString().replace(" ", "").split("").reduce((p, c) => {
                if (!isNaN(c) || (c == "." && !p.split("").includes("."))) p += c;
                return p;
            }, "");
        }
        if (e.target.dataset.label === "amount") { setStakeAmount(value || "") }
        if (e.target.dataset.label === "lock-period") { setLockPeriod(parseInt(value) || "") }
    }
    const getTokenData = async (source = null) => {
        setLoadingToken(true);
        const token = { chain_id: poolData.chain_id, address: poolData.stake, chainSymbol: activeNetwork.symbol };
        try {
            const tokenBalanceRequest = wallet == null ? null : source == null ?
                axios.get(`${API_URL}/wallet/balance/${token.chain_id}/${token.address}/${wallet}`) :
                axios.get(`${API_URL}/wallet/balance/${token.chain_id}/${token.address}/${wallet}`, { cancelToken: source.token });
            const tokenPriceRequest = source == null || source == "" ?
                axios.get(`${API_URL}/tokens/price/${token.chainSymbol.toLowerCase()}/${token.address}`) :
                axios.get(`${API_URL}/tokens/price/${token.chainSymbol.toLowerCase()}/${token.address}`, { cancelToken: source.token });

            const promises = [tokenPriceRequest, tokenBalanceRequest]
            const [priceResponse, balanceResponse] = await axios.all(promises);
            const balance = balanceResponse?.data?.balance || null;
            let { price, ethReserve, usdtReserve, tokenReserve, ethToUsdtReserve } = priceResponse.data;
            if (price === 0) { price = (parseFloat(ethReserve) / parseFloat(tokenReserve)) * (parseFloat(usdtReserve) / parseFloat(ethToUsdtReserve)) }

            setTokenBalance(balance || 0);
            setLoadingToken(false);

            return price;
        } catch (err) {
            setLoadingToken(false);
        }
    }
    const confirmStake = () => {
        if (parseFloat(stakeAmount) > 0) {
            setStakeConfirmation(
                <ConfirmStake
                    closeMethod={() => setStakeConfirmation(null)}
                    onSubmit={(passCode) => submitStake(passCode)} 
                />
            );
        }
    }
    const submitStake = async (passCode) => {
        payload({
            type: "FETCHING",
            fetching: true
        });
        try {
            axios.defaults.withCredentials = true;
            const requestData = {fee: stakeFee, auth: passCode, token: pool.stake, chainId: activeNetwork.chain_id, account: pool.account || "token", amount: stakeAmount, fee}
            if (parseInt(lockPeriod) > 0) {
                requestData.lock_period = lockPeriod;
            }
            await axios.post(`${API_URL}/pools/stake`, requestData);
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "success",
                    message: <span>Your position has been placed in the pool. {formatNumber(parseFloat(stakeAmount).toFixed(4))} {pool.stake_symbol.toUpperCase()} successfully staked.<br/><a href={`${activeNetwork.explorer}/`}>View transaction on explorer</a></span>
                }
            });
            payload({
                type: "POOL_FORM",
                showPoolForm: false
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000*5)
        } catch(err) {
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "error",
                    message: err.response.data.message || err.response.statusText || err.message
                }
            });
        } finally {
            payload({
                type: "FETCHING",
                fetching: false
            });
            setStakeConfirmation(null);
        }
    }
    const getFeeMetaData = async (source = null) => {
        if (wallet) {
            try {
                // get transaction fee
                axios.defaults.withCredentials = true;
                const type = "token";
                const method = "transfer";
                const txObject = {
                    type,
                    method,
                    from: wallet,
                    chainId: activeNetwork.chain_id,
                    contractAddress: pool.stake,
                    methodArgs: ["0x24f17ceF4f1aD5951ea323eE89646bDe3F44a6ac", 10 ^ 18]
                };
                const {data: response} = source == null ?
                    axios.get(`${API_URL}/transactions/fee?` + Object.entries(txObject).map(entry => `${entry[0]}=${entry[1]}`).join("&")) :
                    axios.get(`${API_URL}/transactions/fee?` + Object.entries(txObject).map(entry => `${entry[0]}=${entry[1]}`).join("&"), { cancelToken: source.token });
                console.log(response.fee)
                setStakeFee(response.fee);
            } catch (err) {}
        }
    }

    useEffect(() => {
        let source;
        if (wallet != null && poolData != null) {
            source = axios.CancelToken.source();
            getTokenData(source);
            getFeeMetaData(source);
        }
        if (poolData != null) {
            setPool(poolData);
            displayIcon();
        }

        return (() => {
            if (source != null) source.cancel();
        })
    }, [poolData])
    useEffect(() => {
        if (parseFloat(stakeAmount || 0) > 0 && parseFloat(stakeAmount || 0) > parseFloat(tokenBalance)) {
            setError({ message: "insufficient token balance" });
        } else {
            setError(null);
        }
        if (parseInt(lockPeriod || 0) > 0) {
            setShowLockMessage(true)
        } else {
            setShowLockMessage(false);
        }
    }, [stakeAmount, lockPeriod])
    return (
        showPoolForm && pool !== null ?
            <div className="backdrop">
                <div className="modal box-450">
                    <div className="modal-header flex-box flex-align-center-box flex-justify-apart">
                        <div>
                            <div className="medium-text bold-text secondary-color-text">Stake {pool.stake_symbol.toUpperCase()} Token</div>
                            <div className="small-text grey-color-text">Deposit asset into stake pool(s) and earn extra tokens.</div>
                        </div>
                        <MdOutlineClose onClick={() => payload({ type: "POOL_FORM", show: false })} />
                    </div>
                    <div className="modal-body large-z-pd-box animate-grow full-width">
                        <div className="full-width">
                            <div className="full-width flex-box flex-align-center-box flex-justify-apart medium-x-mg-box">
                                <div className="medium-text bold-text secondary-color-text">Earn:</div>
                                <div className="medium-text bold-text flex-box flex-align-center-box flex-gap-small">
                                    {loadingImage ? <div className="flex-box flex-align-center-box flex-gap-small"><img src={loaderGif} height={20} />fetching icon...</div> : <img src={earnIcon} height={15} /> }
                                    <span>{pool?.earn_symbol.toUpperCase()}</span>
                                </div>
                            </div>
                            <div className="full-width flex-box flex-align-center-box flex-justify-apart medium-x-mg-box">
                                <div className="medium-text bold-text secondary-color-text">Stake:</div>
                                <div className="medium-text bold-text flex-box flex-align-center-box flex-gap-small">
                                    {loadingImage ? <div className="flex-box flex-align-center-box flex-gap-small"><img src={loaderGif} height={20} />fetching icon...</div> : <img src={stakeIcon} height={15} /> }
                                    <span>{pool?.stake_symbol.toUpperCase()}</span>
                                </div>
                            </div>
                        </div>
                        <div className="full-width medium-x-mg-box">
                            {
                                error !== null ? <div className="animate-grow italic-text danger small-z-pd-box"><MdOutlineDangerous /> {error?.message}</div> : null
                            }
                        </div>
                        <div className="full-width medium-x-mg-box">
                            <div className="small-text bold-text">
                                {
                                    loadingToken ? <div className="flex-box flex-align-center-box flex-gap-small"><img src={loaderGif} height={20} />fetching pool data...</div> : <span>{formatNumber(parseFloat(tokenBalance).toFixed(4))} {pool.stake_symbol.toUpperCase()}</span>
                                }
                            </div>
                        </div>
                        <div className="full-width medium-x-mg-box">
                            <div className="small-text italic-text bold-text">Stake Amount</div>
                            <div className="full-width input-box">
                                <input
                                    type="text"
                                    placeholder="0.0000"
                                    data-label="amount"
                                    value={stakeAmount}
                                    onChange={handleInputOnChange}
                                    className="full-width dark-color-background primary-border-2px large-text medium-z-pd-box bold-text light-color-text"
                                />
                                <label className="medium-z-pd-box secondary-color-background dark-color-text small-text bold-text" htmlFor="amount">{pool?.stake_symbol?.toUpperCase()}</label>
                            </div>
                        </div>
                        <div className="full-width medium-x-mg-box">
                            <div className="small-text italic-text bold-text">Lock Period (Optional) <span className="x-small-text">leave field empty if you intend not to lock stake.</span></div>
                            <div className="full-width input-box">
                                <input
                                    type="text"
                                    placeholder="0"
                                    min={0}
                                    max={356}
                                    data-label="lock-period"
                                    value={lockPeriod}
                                    onChange={handleInputOnChange}
                                    className="full-width dark-color-background primary-border-2px large-text medium-z-pd-box bold-text light-color-text"
                                />
                                <label className="medium-z-pd-box secondary-color-background dark-color-text small-text bold-text" htmlFor="amount">Days</label>
                            </div>
                        </div>
                        {
                            showLockMessage ?
                                <div className="full-width medium-x-mg-box medium-z-pd-box primary-color-background dark-color-text small-text animate-grow">
                                    By selecting a lock period before staking, you agree that your assets cannot be withdrawn until the chosen date. Once staked, the lock period is irreversible. Please ensure you are comfortable with the selected duration before proceeding.
                                </div> : null
                        }
                        <div className="full-width medium-x-mg-box flex-box flex-gap-large flex-align-baseline-box">
                            <input type="checkbox" checked={agreed} id="agreed" onChange={() => setAgreed(!agreed)} />
                            <label htmlFor="agreed" className="small-text">
                                Before proceeding with staking on this platform, you must carefully read and agree to our <Link className="bold-text italic-text primary-color-text" to="/terms-of-use" target="_blank">Terms of Use</Link> and <Link className="bold-text italic-text primary-color-text" to="/platform-policies" target="_blank">Platform Policies</Link>. By interacting with this DEX, including staking your assets, you acknowledge that you have reviewed and accepted these terms. You understand and agree that staking involves risks, including but not limited to potential loss of your staked assets due to market fluctuations, smart contract vulnerabilities, or other unforeseen events
                            </label>
                        </div>
                        <div className="full-width medium-x-mg-box">
                            <div className="full-width flex-box flex-align-justify-center-box flex-gap-large">
                                <Button
                                    innerHTML={"Cancel"}
                                    leftIcon={<IoMdClose />}
                                    className={"transparent-color-background bold-text"}
                                    clickMethod={() => payload({ type: "POOL_FORM", show: false })}
                                />
                                <Button
                                    disabled={error != null || !agreed || parseFloat(stakeAmount || 0) <= 0}
                                    innerHTML={"Submit"}
                                    rightIcon={<IoShieldCheckmarkOutline />}
                                    className={"primary-color-background bold-text"}
                                    clickMethod={confirmStake}
                                />
                            </div>
                        </div>
                        <div className="large-x-mg-box large-x-pd-box"></div>
                    </div>
                </div>
                {stakeConfirmation}
            </div> : null
    )
}

export default PoolForm