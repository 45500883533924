import React, { Fragment } from 'react'
import Button from './Button'
import { useStateValue } from '../Context/StateProvider'
import WalletAddressShortener from "./templates/WalletAddressShortener";

import { IoWallet } from "react-icons/io5";

const Forums = () => {
    const [{wallet}, payload] = useStateValue();

    const connectWallet = () => {
        payload({
            type: "WALLET_CONNECT_VISIBILITY",
            showConnectWallet: true
        })
    }

    return (
        <Fragment>
            <section className="container forums">
                <div className="container-1028 forums-container">
                    <div className="banner">
                        Join and Be a Part of this Growing Ecosystem
                    </div>
                    {
                        wallet == null ? 
                            <Button
                                className="primary-color-background"
                                innerHTML={wallet != null ? <WalletAddressShortener address={wallet} /> : "Connect Wallet"}
                                clickMethod={connectWallet}
                                leftIcon={<IoWallet />} 
                            /> : null
                    }
                </div>
            </section>
        </Fragment>
    )
}

export default Forums