import { useState, useEffect } from "react";
import { RiCloseLargeFill } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";
import Button from "./Button";
import { BiSolidSend } from "react-icons/bi";


const ConfirmStake = ({ closeMethod, onSubmit }) => {
    const [passCode, setPassCode] = useState("");

    return (
        <div className="backdrop">
            <div className="modal">
                <div className="modal-header flex-box flex-align-center-box flex-justify-apart">
                    <div>
                        <div className="bold-text secondary-color-text">Confirmation</div>
                        <div className="small-text grey-color-text">Confirm and sign transaction to add trade to mempool.</div>
                    </div>
                    <MdOutlineClose onClick={closeMethod} />
                </div>
                <div className="modal-body">
                    <div className="modal-content">
                        <div className="full-width flex-box flex-direction-column flex-gap-medium flex-align-justify-center-box large-x-pd-box">
                            <input className="auth-input" type="password" placeholder="Pass Code" value={passCode || ""} onChange={(e) => setPassCode(e.target.value)} />
                            <div className="small-text">
                                Provide wallet authentication to sign transaction.
                            </div>
                        </div>
                        <div className="options">
                            <Button
                                className="danger return-btn"
                                innerHTML="Close"
                                clickMethod={closeMethod}
                                leftIcon={<RiCloseLargeFill />}
                            />
                            <Button
                                className="primary-color-background bold-text"
                                innerHTML="Sign Transaction"
                                clickMethod={() => onSubmit(passCode)}
                                rightIcon={<BiSolidSend />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmStake