import React, { Fragment } from "react";
import Header from "../Components/Header";
import HomeBoard from "../Components/HomeBoard";
import Footer from "../Components/Footer";
import Services from "../Components/Services";
import Forums from "../Components/Forums";

const Home = () => {
    return (
        <Fragment>
            <Header />
            <main>
                <HomeBoard />
                <Services />
                <Forums />
            </main>
            <Footer />
        </Fragment>
    )
}

export default Home