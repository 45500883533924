import React, { useEffect, useState } from "react";
import { formatNumber, loadImage } from "../../Context/general";
import loaderGif from "../../Contents/images/loader.gif";
import placeHolderCoin from "../../Contents/images/placeholder-coin.png";
import axios from "axios";
import { useStateValue } from "../../Context/StateProvider";
import Button from "../Button";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { CiCoinInsert } from "react-icons/ci";

const Grid = ({ pool, showOnlyStaked = false }) => {
    const [{ API_URL, wallet, activeNetwork }, payload] = useStateValue();
    const [earnIcon, setEarnIcon] = useState(null);
    const [stakeIcon, setStakeIcon] = useState(null);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingStake, setLoadingStake] = useState(false);
    const [stake, setStake] = useState(null);
    const [tvl, setTvl] = useState(0)
    const [currentEarnings, setCurrentEarnings] = useState(0);
    const [stakeDurationInDays, setStakeDurationInDays] = useState(0)
    const [maxEarnings, setMaxEarnings] = useState(0);
    const [showDetails, setShowDetails] = useState(false);
    const [showPool, setShowPool] = useState(false);
    const [stakeFee, setStakeFee] = useState(0)

    const displayIcon = async () => {
        setLoadingImage(true);
        try {
            const earnThumbnail = pool?.earn_thumbnail ? await loadImage(pool?.earn_thumbnail) : placeHolderCoin;
            const stakeThumbnail = pool?.stake_thumbnail ? await loadImage(pool?.stake_thumbnail) : placeHolderCoin;
            setEarnIcon(earnThumbnail);
            setStakeIcon(stakeThumbnail);
        } catch (err) {
            setEarnIcon(placeHolderCoin);
            setStakeIcon(placeHolderCoin);
        } finally {
            setLoadingImage(false);
        }
    }
    const getStake = async (source = null) => {
        setLoadingStake(true);
        try {
            axios.defaults.withCredentials = true;
            const { data: response } = source === null ?
                await axios.get(`${API_URL}/pools/stakes?pid=${pool.pid}`) :
                await axios.get(`${API_URL}/pools/stakes?pid=${pool.pid}`, { cancelToken: source.token });
            const { stake: _stake } = response;
            setStake(_stake);
            setShowPool(true);
            setCurrentEarnings(earnings(parseFloat(_stake.amount), parseInt(pool.basis_point), _stake.created_at))
        } catch (err) {
            if (showOnlyStaked)
                setShowPool(false);          
        } finally {
            setLoadingStake(false);
        }
    }
    const earnings = (stakeAmount, basisPoint, startDate) => {
        const APY = (basisPoint / 10000) * stakeAmount;
        const secondsPerYear = 60 * 60 * 24 * 365.25;
        const earningsPerSecond = APY / secondsPerYear;
        const secondsSinceStaked = (Date.now() - new Date(startDate).getTime()) / 1000;
        const currentEarnings = earningsPerSecond * secondsSinceStaked;
        setMaxEarnings(APY);
        setStakeDurationInDays((secondsSinceStaked / (60 * 60 * 24)).toFixed(2));
        return currentEarnings;
    }


    useEffect(() => {
        const interval = setInterval(() => {
            if (stake) {
                setCurrentEarnings(earnings(parseFloat(stake.amount), parseInt(pool.basis_point), stake.created_at))
            }
        }, 1000)

        return (() => {
            clearInterval(interval)
        })
    })
    useEffect(() => {
        if (!showOnlyStaked || showOnlyStaked && stake !== null) {
            setShowPool(true)
        } else {
            setShowPool(false);
        }
    }, [showOnlyStaked, stake])
    useEffect(() => {
        let source;
        if (pool != null) {
            displayIcon();
            if (wallet) {
                source = axios.CancelToken.source();
                getStake(source);
            }
        }

        return (() => {
            if (source != null) {source.cancel();}
        })
    }, [pool, wallet])
    return (
        showPool ?
            <div className="large-x-pd-box fade-color-background animate-grow">
                <div className="full-width flex-box flex-align-center-box flex-justify-apart large-z-pd-box">
                    <div className="bold-text">
                        <h1 className="medium-text primary-color-text">Earn {pool?.earn_symbol.toUpperCase()}</h1>
                        <strong className="small-text secondary-color-text">Stake {pool?.stake_symbol.toUpperCase()}</strong>
                    </div>
                    {loadingImage ?
                        <img src={loaderGif} alt="loading..." height={30} /> :
                        <div className="mixed-img-large-small">
                            <img className="large" src={earnIcon} alt={pool?.earn_symbol} height={30} />
                            <img className="small" src={stakeIcon} alt={pool?.earn_symbol} height={30} />
                        </div>
                    }
                </div>
                {
                    loadingStake ?
                        <div className="full-width flex-box flex-align-center-box flex-gap-medium large-y-pd-box">
                            <img src={loaderGif} alt="" height={20} />
                            <span>fetching stake data...</span>
                        </div> :
                        !loadingStake && stake !== null ?
                            <div className="full-width large-z-pd-box">
                                <div className="flex-box flex-direction-column medium-z-pd-box">
                                    <div className="flex-box flex-align-center-box flex-justify-apart">
                                        <div className="small-text bold-text">Staked: </div>
                                        <div className="medium-text bold-text italic-text ">{formatNumber(stake?.amount)} {pool.stake_symbol.toUpperCase()}</div>
                                    </div>
                                    <div className="flex-box flex-align-center-box flex-justify-apart">
                                        <div className="small-text bold-text">Earned: </div>
                                        <div className="medium-text bold-text italic-text ">{formatNumber(parseFloat(currentEarnings).toFixed(5))} {pool.stake_symbol.toUpperCase()}</div>
                                    </div>
                                </div>
                                <div className="primary-color-text medium-z-pd-box small-text">Locked for <span className="bold-text secondary-color-text">{stakeDurationInDays}</span> of <span className="bold-text secondary-color-text">{stake.lock_period}</span> days.</div>

                            </div> : null

                }
                <div className="full-width large-z-pd-box">
                    <div className="full-width flex-box flex-align-center-box flex-justify-apart small-z-pd-box">
                        <div className="medium-text bold-text">TVL</div>
                        <div className="small-text bold-text">{tvl} {pool?.stake_symbol?.toUpperCase()}</div>
                    </div>
                    <div className="full-width flex-box flex-align-center-box flex-justify-apart small-z-pd-box">
                        <div className="medium-text bold-text">APR</div>
                        <div className="small-text bold-text">{pool.basis_point / 100}%</div>
                    </div>
                </div>
                <div className="full-width large-z-pd-box">
                    {
                        stake === null ?
                            <div className="full-width">
                                <div className="full-width flex-box flex-align-center-box flex-gap-medium x-small-text">
                                    <strong>Stake</strong>
                                    <strong className="secondary-color-text">{pool.stake_symbol.toUpperCase()}</strong>
                                </div>
                                <Button
                                    className={"primary-color-background full-width bold-text"}
                                    innerHTML={"Stake"}
                                    rightIcon={<CiCoinInsert />}
                                    clickMethod={() => payload({ type: "POOL_FORM", data: {...pool, stakeFee}, show: true })}
                                />
                            </div> :
                            <div className="full-width">
                                <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                                    <div className="medium-text bold-text italic-text primary-color-text">{parseFloat(currentEarnings).toFixed(5)} {pool.earn_symbol.toUpperCase()}</div>
                                    <Button
                                        className={`transparent-background bold-text ${parseInt(stake.lock_period) > stakeDurationInDays ? "disabled" : "primary-border-2px primary-color-text"}`}
                                        innerHTML={"Unstake"}
                                        disabled={parseInt(stake.lock_period) > stakeDurationInDays}
                                        clickMethod={() => alert("Ok")}
                                    />
                                </div>
                                <div className="full-width large-x-mg-box">
                                    <Button
                                        className={"primary-color-background full-width bold-text"}
                                        innerHTML={"Increase Stake"}
                                        rightIcon={<CiCoinInsert />}
                                        clickMethod={() => payload({ type: "POOL_FORM", data: pool, show: true })}
                                    />
                                </div>
                            </div>
                    }
                </div>
                <hr className="large-x-mg-box" />
                <div className="full-width small-z-pd-box flex-box flex-align-center-box flex-justify-apart">
                    <div></div>
                    <Button
                        clickMethod={() => setShowDetails(!showDetails)}
                        className={"transparent-background secondary-color-text bold-text"}
                        innerHTML={showDetails ? "Hide" : "Details"}
                        rightIcon={showDetails ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                    />
                </div>
                {
                    showDetails ?
                        <div className="full-width">
                            <div className="full-width flex-box flex-align-center-box flex-justify-apart medium-z-pd-box small-text">
                                <strong>STAKING TERMS</strong>
                                <strong>{stake === null ? pool.unstake_period : parseInt(stake.lock_period) + parseInt(pool.unstake_period)} days</strong>
                            </div>
                            <div className="full-width flex-box flex-align-center-box flex-justify-apart medium-z-pd-box small-text">
                                <strong>MAX PAYOUT</strong>
                                <strong>{maxEarnings} {pool?.stake_symbol?.toUpperCase()}</strong>
                            </div>
                            <div className="full-width flex-box flex-align-center-box flex-justify-apart medium-z-pd-box small-text">
                                <strong>HARVEST FEE</strong>
                                <strong>{stake !== null ? `${maxEarnings * (3 / 100)} ${pool?.stake_symbol?.toUpperCase()}` : null} 3% of earnings</strong>
                            </div>
                            <div className="large-x-mg-box large-x-pd-box"></div>
                        </div> : null
                }
            </div> : null
    )
}

export default Grid