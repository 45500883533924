import { useState, useEffect, Fragment } from "react";
import { useStateValue } from "../Context/StateProvider";
import { FaCheck } from "react-icons/fa6";
import { RiCloseLargeFill } from "react-icons/ri";
import { formatNumber, formatToCurrency } from "../Context/general";
import { MdOutlineClose } from "react-icons/md";
import placeHolderCoin from "../Contents/images/placeholder-coin.png";
import { TbSwitch3 } from "react-icons/tb";
import Button from "./Button";
import { BiSolidSend } from "react-icons/bi";


const ConfirmTrade = ({ activeNetwork, baseAsset, quoteAsset, netTokenUsdValue, tradeFee, tradeSlippage, closeMethod, onSubmit, tradeMethod = "Swap" }) => {
    const [{ API_URL }] = useStateValue()
    const [page, setPage] = useState(1);
    const [buyFor, setBuyFor] = useState(0);
    const [passCode, setPassCode] = useState("");

    useEffect(() => {
        const initialBuyFor = parseFloat(quoteAsset?.amount) - ((parseFloat(quoteAsset?.amount) * tradeSlippage) / 100);
        setBuyFor(initialBuyFor);
    }, [])

    return (
        <div className="backdrop">
            <div className="modal">
                <div className="modal-header flex-box flex-align-center-box flex-justify-apart">
                    <div>
                        <div className="bold-text secondary-color-text">Confirmation</div>
                        <div className="small-text grey-color-text">Confirm and sign transaction to add trade to mempool.</div>
                    </div>
                    <MdOutlineClose onClick={closeMethod} />
                </div>
                <div className="modal-body">
                    <div className="modal-content">
                        {
                            page === 1 ? (
                                <Fragment>
                                    <div className="trade-details full-width large-x-mg-box">
                                        <div className="full-width flex-box flex-align-justify-center-box flex-gap-large">
                                            <div className="bold-text flex-box flex-align-center-box flex-gap-medium animate-grow">
                                                <img src={baseAsset.thumbnail || placeHolderCoin} alt="" className="xx-large-icon" />
                                                <span>{baseAsset?.symbol.toUpperCase()}</span>
                                            </div>
                                            <div className="bold-text animate-grow">
                                                <TbSwitch3 />
                                            </div>
                                            <div className="bold-text flex-box flex-align-center-box flex-gap-medium animate-grow">
                                                <span>{quoteAsset?.symbol.toUpperCase()}</span>
                                                <img src={quoteAsset.thumbnail || placeHolderCoin} alt="" className="xx-large-icon" />
                                            </div>
                                        </div>
                                        <div className="full-width flex-box flex-align-justify-center-box large-text large-x-mg-box">
                                            <strong className="animate-grow">{tradeMethod}</strong>
                                        </div>
                                        <div className="full-width flex-box flex-align-justify-center-box flex-gap-large">
                                            <div className="flex-box flex-direction-column flex-align-center-box bold-text">
                                                <div className="red-text medium-text">{formatNumber(parseFloat(baseAsset.amount).toFixed(4))} <span className="primary-color-text">{baseAsset.symbol.toUpperCase()}</span></div>
                                                <div style={{ fontStyle: "italic" }} className="small-text large-y-pd-box small-x-pd-box">{formatToCurrency(parseFloat(baseAsset.amount) * parseFloat(baseAsset.price), "usd")}</div>
                                            </div>
                                            <div className="bold-text">
                                                <span className="primary-color-text bold-text" style={{ fontStyle: "italic" }}>{tradeMethod.toLowerCase() == "swap" ? "for" : "with"}</span>
                                            </div>
                                            <div className="flex-box flex-direction-column flex-align-center-box bold-text">
                                                <div className="green-text medium-text">{formatNumber(parseFloat(buyFor).toFixed(4))} <span className="primary-color-text">{quoteAsset.symbol.toUpperCase()}</span></div>
                                                <div style={{ fontStyle: "italic" }} className="small-text large-y-pd-box small-x-pd-box">{formatToCurrency(parseFloat(buyFor) * parseFloat(quoteAsset.price), "usd")}</div>
                                            </div>
                                        </div>
                                        <div className="full-width large-z-pd-box">
                                            <div className="full-width large-y-pd-box small-x-pd-box flex-box flex-align-justify-center-box flex-gap-large">
                                                <span className="title small-text bold-text primary-color-text">Fee</span>
                                                <span className="value small-text">{tradeFee.toFixed(4)} {activeNetwork.symbol.toUpperCase()}</span>
                                            </div>
                                            <div className="full-width large-y-pd-box small-x-pd-box flex-box flex-align-justify-center-box flex-gap-large">
                                                <span className="title small-text bold-text primary-color-text">Total</span>
                                                <span className="value small-text">
                                                    {
                                                        tradeMethod.toLowerCase() != "swap" ? 
                                                            formatToCurrency(
                                                                (parseFloat(tradeFee) * parseFloat(netTokenUsdValue)) +
                                                                (parseFloat(baseAsset.amount) * parseFloat(baseAsset.price)) +
                                                                (parseFloat(quoteAsset.amount) * parseFloat(quoteAsset.price)),
                                                                "usd"
                                                            ) : 
                                                            formatToCurrency(
                                                                (parseFloat(tradeFee) * parseFloat(netTokenUsdValue)) +
                                                                (parseFloat(baseAsset.amount) * parseFloat(baseAsset.price)),
                                                                "usd"
                                                            )

                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="options">
                                        <Button
                                            className="danger return-btn"
                                            innerHTML="Decline"
                                            clickMethod={closeMethod}
                                            leftIcon={<RiCloseLargeFill />}
                                        />
                                        <Button
                                            className="primary-color-background bold-text green-text"
                                            innerHTML="Confirm"
                                            clickMethod={() => setPage(2)}
                                            rightIcon={<FaCheck />}
                                        />
                                    </div>
                                </Fragment>
                            ) :
                                page === 2 ? (
                                    <Fragment>
                                        <div className="full-width flex-box flex-direction-column flex-gap-medium flex-align-justify-center-box large-x-pd-box">
                                            <input className="auth-input" type="password" placeholder="Pass Code" value={passCode || ""} onChange={(e) => setPassCode(e.target.value)} />
                                            <div className="small-text">
                                                Provide wallet authentication to sign transaction.
                                            </div>
                                        </div>
                                        <div className="options">
                                            <Button
                                                className="danger return-btn"
                                                innerHTML="Close"
                                                clickMethod={closeMethod}
                                                leftIcon={<RiCloseLargeFill />}
                                            />
                                            <Button
                                                className="primary-color-background bold-text"
                                                innerHTML="Sign Transaction"
                                                clickMethod={() => onSubmit(passCode)}
                                                rightIcon={<BiSolidSend />}
                                            />
                                        </div>
                                    </Fragment>
                                ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmTrade