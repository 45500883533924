import React from 'react'

const WalletAddressShortener = ({ address }) => {
    return (
        <div className="short-address">
            <span>{address.slice(0, 4)}</span>
            <span>****</span>
            <span>{address.slice(address.length - 4, address.length)}</span>
        </div>
    )
}

export default WalletAddressShortener