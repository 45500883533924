import React from "react";

import logo from "../Contents/images/logo.png"
import { Link } from "react-router-dom";

import { RiTwitterXFill } from "react-icons/ri";
import { PiTelegramLogoLight } from "react-icons/pi";
import { SlSocialYoutube } from "react-icons/sl";
import { TfiInstagram } from "react-icons/tfi";

const Footer = () => {
    return (
        <footer>
            <nav className="footer-container container-1028">
                <section className="full-width section-1">
                    <div className="info">
                        <div className="site-maps full-width">
                            <div className="map">
                                <div className="title">Ecosystem</div>
                                <div className="items">
                                    <div className="item">
                                        <Link to="/trade/swap">Swap Tokens</Link>
                                    </div>
                                </div>
                                <div className="items">
                                    <div className="item">
                                        <Link to="/trade/pools">Stake to Liquidity Pools</Link>
                                    </div>
                                </div>
                                <div className="items">
                                    <div className="item">
                                        <Link to="/trade/farm">Farm LP Tokens</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="map">
                                <div className="title">Support</div>
                                <div className="items">
                                    <div className="item">
                                        <Link to="/">Contact</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="map">
                                <div className="title">About</div>
                                <div className="items">
                                    <div className="item">
                                        <Link to="/">Terms of Use</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="map">
                                <div className="title">Developers</div>
                                <div className="items">
                                    <div className="item">
                                        <Link to="/">Github</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="map">
                                <div className="title">Business</div>
                                <div className="items">
                                    <div className="item">
                                        <Link to="/">IFO</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="socials full-width">
                            <div className="social-link">
                                <a href="http://" target="_blank" rel="noopener noreferrer">
                                    <div className="floater">Twitter</div>
                                    <RiTwitterXFill />
                                </a>
                            </div>
                            <div className="social-link">
                                <a href="http://" target="_blank" rel="noopener noreferrer">
                                    <div className="floater">Telegram</div>
                                    <PiTelegramLogoLight />
                                </a>
                            </div>
                            <div className="social-link">
                                <a href="http://" target="_blank" rel="noopener noreferrer">
                                    <div className="floater">YouTube</div>
                                    <SlSocialYoutube />
                                </a>
                            </div>
                            <div className="social-link">
                                <a href="http://" target="_blank" rel="noopener noreferrer">
                                    <div className="floater">Instagram</div>
                                    <TfiInstagram />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-logo">
                        <div className="logo-container">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                </section>
                <section className="full-width section-2">
                    <div className="reference">
                        &copy; {(new Date()).getFullYear()}. All rights reserved.
                    </div>
                </section>
            </nav>
        </footer>
    )
}

export default Footer