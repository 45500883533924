import React, { useEffect, useState } from "react";
import { GiPlantSeed } from "react-icons/gi";
import { PiHandDeposit } from "react-icons/pi";
import { RiTokenSwapLine } from "react-icons/ri";
import { SlSettings } from "react-icons/sl";
import { useStateValue } from "../Context/StateProvider";
import { useNavigate } from "react-router-dom";

const MobileFooterMenu = () => {
	const [{showSettings}, payload] = useStateValue();
	const [activeMenu, setActiveMenu] = useState("swap");


    const navigate = useNavigate();

    const redirectTo = (location) => {
        return navigate(`/${location}`, { relative: "path" });
    }

	const handleMenuItemOnClick = (location) => {
		switch(location) {
			case "SWAP":
				setActiveMenu("swap");
				redirectTo("trade/swap");
				break;
			case "STAKE":
				setActiveMenu("stake");
				redirectTo("trade/pools");
				break;
			case "FARM":
				setActiveMenu("farm");
				redirectTo("trade/farm");
				break;
			case "SETTINGS":
				payload({
					type: "SETTINGS",
					showSettings: true
				})
				break;
		}
	}

	useEffect(() => {

	}, [])

	return (
		<div className="footer-menu full-width">
			<div className={`menu-item ${activeMenu == "swap" ? "active" : ""}`} onClick={() => handleMenuItemOnClick("SWAP")}>
				<div className="icon"><RiTokenSwapLine /></div>
				<div className="text bold-text">Swap</div>
			</div>
			<div className={`menu-item ${activeMenu == "stake" ? "active" : ""}`} onClick={() => handleMenuItemOnClick("STAKE")}>
				<div className="icon"><PiHandDeposit /></div>
				<div className="text bold-text">Stake</div>
			</div>
			<div className={`menu-item ${activeMenu == "farm" ? "active" : ""}`} onClick={() => handleMenuItemOnClick("FARM")}>
				<div className="icon"><GiPlantSeed /></div>
				<div className="text bold-text">Farm</div>
			</div>
			<div className={`menu-item ${activeMenu == "settings" && showSettings ? "active" : ""}`} onClick={() => handleMenuItemOnClick("SETTINGS")}>
				<div className="icon"><SlSettings /></div>
				<div className="text bold-text">Settings</div>
			</div>
		</div>
	)
}

export default MobileFooterMenu