import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "../Context/StateProvider";
import { shortAddress } from "../Context/general";
import Button from "./Button";

import { HiMiniRocketLaunch, HiAcademicCap } from "react-icons/hi2";
import { IoWallet } from "react-icons/io5";
import { MdKeyboardArrowDown, MdForum, MdPlaylistAddCheckCircle, MdOutlineCloseFullscreen } from "react-icons/md";
import { TbPresentationAnalytics } from "react-icons/tb";
import { BsFillPostcardFill } from "react-icons/bs";
import { RiTeamFill } from "react-icons/ri";
import { FaHandshakeSimple } from "react-icons/fa6";

import logo from "../Contents/images/logo.png"
import { CgMenuGridO } from "react-icons/cg";
import WalletAddressShortener from "./templates/WalletAddressShortener";

const menus = {
    more: [
        {
            title: "Analytic",
            message: "Find the best opportunity.",
            location: "analytics",
            icon: <TbPresentationAnalytics />
        },
        {
            title: "Blog",
            message: "Stay up to data with the latest product development at Decentrade.",
            location: "trade",
            icon: <BsFillPostcardFill />
        },
        {
            title: "Academy",
            message: "Everything you need to get up to speed with DeFi.",
            location: "trade",
            icon: <HiAcademicCap />
        },
        {
            title: "Forum & Proposals",
            message: "View and discuss proposal for Decentrade.",
            location: "trade",
            icon: <MdForum />
        },
        {
            title: "Participate",
            message: "As Cart holder, you can vote on proposals to shape the future of Decentrade.",
            location: "trade",
            icon: <RiTeamFill />
        }
    ],
    partners: [
        {
            title: "Partner with us",
            message: "Incentivize your token with Decentrade rewards",
            location: "trade",
            icon: <FaHandshakeSimple />
        },
        {
            title: "List enquiry",
            message: "get your token on our default token list",
            location: "trade",
            icon: <MdPlaylistAddCheckCircle />
        }
    ]
}

const MenuDropContent = ({ links, handleOnClickRedirect }) => {
    return (
        links && links.length > 0 ? links.map(({ title, message, location, icon }, index) => (
            <div className="cursor-pointer secondary-color-text-hover" key={index} onClick={() => handleOnClickRedirect(location)}>
                <div className="menu-drop-content-item">
                    <div>{icon}<span>{title}</span></div>
                    <div>{message}</div>
                </div>
            </div>
        )) : null
    )
}

const Header = () => {
    const [{ wallet }, payload] = useStateValue();
    const [dropContent, setDropContent] = useState(null);
    const [showDropDown, setShowDropDown] = useState(false);
    const [showDropDownOnMouseAction, setShowDropDownOnMouseAction] = useState(false);
    const [showMobilMenu, setShowMobileMenu] = useState(false);
    const [dropTitle, setDropTitle] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const connectWallet = () => {
        payload({
            type: "WALLET_CONNECT_VISIBILITY",
            showConnectWallet: true
        })
    }
    const disConnectWallet = () => {
        payload({
            type: "WALLET_DISCONNECT_VISIBILITY",
            showDisconnectWallet: true
        });
    }

    const redirectTo = (location) => {
        return navigate(`/${location}`, { relative: "path" });
    }

    const handleOnClickRedirect = (location) => {
        setDropTitle(null);
        setShowDropDown(false);
        setShowMobileMenu(false);
        setDropContent(null);
        redirectTo(location);
    }

    const makeMenuDropContent = (menuTitle) => {
        setShowDropDownOnMouseAction(false);
        setDropContent(null)
        if (menuTitle === dropTitle) menuTitle = null;
        switch (menuTitle) {
            case "more":
                setDropContent(<MenuDropContent links={menus.more} handleOnClickRedirect={handleOnClickRedirect} />)
                break;
            case "partners":
                setDropContent(<MenuDropContent links={menus.partners} handleOnClickRedirect={handleOnClickRedirect} />)
                break;
            default:
                setDropContent(null)
        }
        setDropTitle(menuTitle);
        setShowDropDownOnMouseAction(true);
    }

    useEffect(() => {
        if (showDropDown == false && showDropDownOnMouseAction == false) {
            makeMenuDropContent(null);
        }
    }, [showDropDown, showDropDownOnMouseAction])

    return (
        <Fragment>
            <header className="desktop">
                <nav>
                    <div className="left-column">
                        <div className="header-logo">
                            <Link to="/">
                                <img src={logo} alt="Logo" />
                            </Link>
                        </div>
                        <div className="menu">
                            <div className="menu-container">
                                {
                                    location.pathname == "/trade" ? (
                                        <div className="menu-item" onClick={() => redirectTo("trade/pools")}>
                                            <span>Pools</span>
                                        </div>
                                    ) :
                                        location.pathname == "/trade/swap" ? (
                                            <div className="menu-item" onClick={() => redirectTo("trade/farm")}>
                                                <span>Farm</span>
                                            </div>
                                        ) :
                                            location.pathname == "/trade/pools" ? (
                                                <div className="menu-item" onClick={() => redirectTo("trade/swap")}>
                                                    <span>Swap</span>
                                                </div>
                                            ) :
                                                (
                                                    <div className="menu-item" onClick={() => redirectTo("trade/swap")}>
                                                        <span>Trade</span>
                                                    </div>
                                                )
                                }
                                {
                                    ["/", "/trade/swap", "/trade/farm"].includes(location.pathname) ? (
                                        <div className="menu-item" onClick={() => redirectTo("trade/pools")}>
                                            <span>Pools</span>
                                        </div>
                                    ) : (
                                        <div className="menu-item" onClick={() => redirectTo("trade/farm")}>
                                            <span>Farm</span>
                                        </div>
                                    )
                                }
                                <div
                                    className="menu-item"
                                    onMouseEnter={() => makeMenuDropContent("more")} onMouseLeave={() => setShowDropDownOnMouseAction(false)}>
                                    <span>More</span> <MdKeyboardArrowDown />
                                </div>
                                <div
                                    className="menu-item"
                                    onMouseEnter={() => makeMenuDropContent("partners")} onMouseLeave={() => setShowDropDownOnMouseAction(false)}>
                                    <span>Partners</span> <MdKeyboardArrowDown />
                                </div>
                            </div>
                            {
                                dropContent != null ? (
                                    <div className="menu-drop-content-container" onMouseLeave={() => setShowDropDown(false)} onMouseEnter={() => setShowDropDown(true)}>
                                        {dropContent}
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="right-column">
                        <div className="actions">
                            <Button
                                className="primary-color-background"
                                innerHTML={wallet != null ? <WalletAddressShortener address={wallet} /> : "Connect Wallet"}
                                clickMethod={wallet != null ? disConnectWallet : connectWallet}
                                leftIcon={<IoWallet />} />
                            {
                                !["/trade", "/trade/swap", "/trade/farm", "/trade/pools"].includes(location.pathname) ? (
                                    <Button
                                        className="secondary-color-background"
                                        clickMethod={() => redirectTo("trade/swap")}
                                        innerHTML="Launch App"
                                        leftIcon={<HiMiniRocketLaunch />} />
                                ) : null
                            }
                        </div>
                    </div>
                </nav>
            </header>
            <header className="tab">
                <div className="full-width large-z-pd-box flex-box flex-align-center-box flex-justify-apart">
                    <Link to="/">
                        <img className="large-icon" src={logo} alt="Logo" />
                    </Link>
                    {
                        showMobilMenu ? 
                            <MdOutlineCloseFullscreen 
                                className="large-icon cursor-pointer"
                                onClick={() => setShowMobileMenu(false)} /> : 
                            <CgMenuGridO 
                                className="large-icon cursor-pointer"
                                onClick={() => setShowMobileMenu(true)} />
                    }
                </div>
                {
                    showMobilMenu ? <div className="mobile-menu full-width animate-slide-right">
                        <div className="menu medium-z-pd-box flex-box">
                            <div className="menu-container flex-box flex-direction-column flex-gap-large">
                                {
                                    location.pathname == "/trade" ? (
                                        <div className="menu-item flex-box flex-align-center-box large-z-pd-box " onClick={() => handleOnClickRedirect("trade/pools")}>
                                            <span>Pools</span>
                                        </div>
                                    ) :
                                        location.pathname == "/trade/swap" ? (
                                            <div className="menu-item flex-box flex-align-center-box large-z-pd-box " onClick={() => handleOnClickRedirect("trade/farm")}>
                                                <span>Farm</span>
                                            </div>
                                        ) :
                                            location.pathname == "/trade/pools" ? (
                                                <div className="menu-item flex-box flex-align-center-box large-z-pd-box " onClick={() => handleOnClickRedirect("trade/swap")}>
                                                    <span>Swap</span>
                                                </div>
                                            ) :
                                                (
                                                    <div className="menu-item flex-box flex-align-center-box large-z-pd-box " onClick={() => handleOnClickRedirect("trade/swap")}>
                                                        <span>Trade</span>
                                                    </div>
                                                )
                                }
                                {
                                    ["/", "/trade/swap", "/trade/farm"].includes(location.pathname) ? (
                                        <div className="menu-item flex-box flex-align-center-box large-z-pd-box " onClick={() => handleOnClickRedirect("trade/pools")}>
                                            <span>Pools</span>
                                        </div>
                                    ) : (
                                        <div className="menu-item flex-box flex-align-center-box large-z-pd-box " onClick={() => handleOnClickRedirect("trade/farm")}>
                                            <span>Farm</span>
                                        </div>
                                    )
                                }
                                <div
                                    className={`menu-item flex-box flex-align-center-box large-z-pd-box ${dropTitle === "more" ? "active bold-text" : ""}`}
                                    onClick={() => makeMenuDropContent("more")} >
                                    <span>More</span> <MdKeyboardArrowDown />
                                </div>
                                <div
                                    className={`menu-item flex-box flex-align-center-box large-z-pd-box ${dropTitle === "partners" ? "active bold-text" : ""}}`}
                                    onClick={() => makeMenuDropContent("partners")}>
                                    <span>Partners</span> <MdKeyboardArrowDown />
                                </div>
                            </div>
                            {
                                dropContent != null ? (
                                    <div className="menu-drop-content-container animate-slide-left" onMouseLeave={() => setShowDropDown(false)} onMouseEnter={() => setShowDropDown(true)}>
                                        {dropContent}
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="actions flex-box flex-align-center-box flex-gap-large">
                            <Button
                                className="primary-color-background"
                                innerHTML={wallet != null ? <WalletAddressShortener address={wallet} /> : "Connect Wallet"}
                                clickMethod={wallet != null ? disConnectWallet : connectWallet}
                                leftIcon={<IoWallet />} />
                            {
                                !["/trade", "/trade/swap", "/trade/farm", "/trade/pools"].includes(location.pathname) ? (
                                    <Button
                                        className="secondary-color-background"
                                        clickMethod={() => handleOnClickRedirect("trade/swap")}
                                        innerHTML="Launch App"
                                        leftIcon={<HiMiniRocketLaunch />} />
                                ) : null
                            }
                        </div>
                    </div> : null
                }
            </header>
        </Fragment>
    )
}

export default Header;