import React, { Fragment, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import Button from "./Button";

import { GoArrowDown } from "react-icons/go";
import { IoWalletOutline } from "react-icons/io5";
import { FaRegHandshake } from "react-icons/fa6";

import daiLogo from "../Contents/images/dai-logo.png";
import etherLogo from "../Contents/images/ether-logo.png";
import optimismLogo from "../Contents/images/optimism.svg";
import layerzeroLogo from "../Contents/images/layerzero.svg";
import barnbridgeLogo from "../Contents/images/barnbridge.svg";
import magnaLogo from "../Contents/images/magna.svg";
import arbitrumLogo from "../Contents/images/arbitrum.svg";
import zksyncLogo from "../Contents/images/zksync.svg";
import polygonLogo from "../Contents/images/polygon.svg";

const aspects = ["Finance", "Content", "Gaming", "Governance"]
let counter = 1;

const HomeBoard = () => {
    const [selectedAspect, setSelectedAspect] = useState(<div className="animate-slide-left primary-color-text">{aspects[0]}</div>);

    const navigate = useNavigate();

    const redirectTo = (location) => {
        return navigate(`/${location}`, { relative: "path" });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedAspect("")
            setTimeout(() => {
                setSelectedAspect(<div className="animate-slide-left primary-color-text">{aspects[counter % aspects.length]}</div>);
            }, 50)
            counter++;
        }, 4 * 1000);

        return (() => {
            clearInterval(interval);
        });
    }, [])

    return (
        <Fragment>
            <section className="container-1028">
                <div className="home-board">
                    <div className="left-board">
                        <div className="board-header">
                            Trade Freely and Securely—Embrace the Future of {selectedAspect}
                        </div>
                        <div className="board-caption">
                            Experience ultimate trading freedom on our decentralized exchange—secure, private, and fully controlled by you. Trade crypto your way!
                        </div>
                    </div>
                    <div className="right-board flex-box flex-align-justify-center-box">
                        <div className="trade-container" style={{width: 400}}>
                            <div className="controls">
                                <div className="control-item active">Swap</div>
                                <div className="control-item">Limit</div>
                            </div>
                            <div className="content-container">
                                <div className="asset-selector top">
                                    <div>
                                        <div className="value">2.00</div>
                                        <div className="token-selector">
                                            <div className="token-selector-btn">
                                                <img src={etherLogo} alt="" />
                                                <div className="token-name">ETH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="usd-value">$ 5235.62</div>
                                        <div className="wallet-balance">
                                            <IoWalletOutline />
                                            <span>0.00</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="demarcator">
                                    <div className="demarcator-knob hover-spin">
                                        <GoArrowDown />
                                    </div>
                                </div>
                                <div className="asset-selector bottom">
                                    <div>
                                        <div className="value">5165.24</div>
                                        <div className="token-selector">
                                            <div className="token-selector-btn">
                                                <img src={daiLogo} alt="" />
                                                <div className="token-name">DAI</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="usd-value">$ 5135.26</div>
                                        <div className="wallet-balance">
                                            <IoWalletOutline />
                                            <span>0.00</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="trade-data">
                                    <Button
                                        className="primary-color-background full-width"
                                        innerHTML="Trade Now"
                                        clickMethod={() => redirectTo("trade/swap")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dex-stats">
                    <div className="stats-desc">
                        Shaping the Future of Decentralized Trading
                    </div>
                    <div className="stats-slide">
                        <div className="stats-block">
                            <div className="value">$0.23</div>
                            <div className="item">Price</div>
                        </div>
                        <div className="stats-block">
                            <div className="value">$60M</div>
                            <div className="item">Total Liquidity</div>
                        </div>
                        <div className="stats-block">
                            <div className="value">$5.7B</div>
                            <div className="item">Total Volume</div>
                        </div>
                        <div className="stats-block">
                            <div className="value">16.8K</div>
                            <div className="item">Total Pairs</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container networking">
                <div className="partners full-width">
                    <div className="title">
                        Partnerships that Drive Us
                    </div>
                    <div className="note">
                        We deeply appreciate our partners for their unwavering support and collaboration. Together, we are setting new standards in the decentralized exchange landscape, driving innovation and trust. Thank you for being an integral part of our journey towards a more transparent and decentralized financial future
                    </div>
                    <div className="partners-slide full-width">
                        <div className="slide-container">
                            <div className="slide">
                                <div className="slide-img">
                                    <img src={optimismLogo} alt="" />
                                </div>
                            </div>
                            <div className="slide">
                                <div className="slide-img">
                                    <img src={layerzeroLogo} alt="" />
                                </div>
                            </div>
                            <div className="slide">
                                <div className="slide-img">
                                    <img src={barnbridgeLogo} alt="" />
                                </div>
                            </div>
                            <div className="slide">
                                <div className="slide-img">
                                    <img src={magnaLogo} alt="" />
                                </div>
                            </div>
                            <div className="slide">
                                <div className="slide-img">
                                    <img src={arbitrumLogo} alt="" />
                                </div>
                            </div>
                            <div className="slide">
                                <div className="slide-img">
                                    <img src={zksyncLogo} alt="" />
                                </div>
                            </div>
                            {/* <div className="slide">
                                <div className="slide-img">
                                    <img src={thundercoreLogo} alt="" />
                                </div>
                            </div> */}
                            <div className="slide">
                                {/* <div className="slide-img">
                                    <img src={polygonLogo} alt="" />
                                </div> */}
                                <img src={polygonLogo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default HomeBoard