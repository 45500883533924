import React, { useEffect, useRef, useState } from 'react'
import { formatNumber, loadImage } from '../Context/general';
import axios from 'axios';
import { useStateValue } from '../Context/StateProvider';
import placeHolderCoin from "../Contents/images/placeholder-coin.png";
import loaderGif from "../Contents/images/loader.gif";
import Button from './Button';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { HiCollection } from 'react-icons/hi';
import { PiPlantFill } from 'react-icons/pi';

const Farmed = ({ farm, keyId, addLiquidityMethod, harvestMethod }) => {
    const [{ API_URL }, payload] = useStateValue();
    const [farmDetail, setFarmDetail] = useState(null);
    const [baseIcon, setBaseIcon] = useState(placeHolderCoin);
    const [quoteIcon, setQuoteIcon] = useState(placeHolderCoin);
    const [showDetails, setShowDetails] = useState(false);

    const isRequestDetailInProgress = useRef(false);
    const displayIcon = async () => {
        try {
            const baseImageSrc = farmDetail?.base_thumbnail != null ? await loadImage(farmDetail?.base_thumbnail) : placeHolderCoin;
            const quoteImageSrc = farmDetail?.quote_thumbnail != null ? await loadImage(farmDetail?.quote_thumbnail) : placeHolderCoin;
            setBaseIcon(baseImageSrc);
            setQuoteIcon(quoteImageSrc);
        } catch (err) { }
    }
    const getFarmDetails = async (source = null) => {
        isRequestDetailInProgress.current = true;
        try {
            const { data: response } = source == null ?
                await axios.get(`${API_URL}/farm?base=${farm.data.base}&quote=${farm.data.quote}`) :
                await axios.get(`${API_URL}/farm?base=${farm.data.base}&quote=${farm.data.quote}`, { cancelToken: source.token });
            setFarmDetail(response.farms[0] || {});
        } catch (err) {
            if (err.message != "canceled") {
                payload({
                    type: "NOTIFICATION",
                    notification: {
                        status: "error",
                        message: "could not fetch farm detail. Please refresh page."
                    }
                });
            }
        } finally {
            isRequestDetailInProgress.current = false;
        }
    }

    // useEffect(() => {
    //     const source = axios.CancelToken.source();
    //     getFarmDetails(source);

    //     // return (() => {
    //     //     source.cancel();
    //     // })
    // }, []);
    useEffect(() => {
        let source;
        if (farmDetail != null) {
            displayIcon();
        } else {
            source = axios.CancelToken.source();
            getFarmDetails(source);
        }
        return (() => {
            if (source != null) source.cancel();
        })
    }, [farmDetail])
    return (
        <div className="full-width primary-box-shadow-s small-x-mg-box" key={keyId}>
            <div className="full-width dark-color-background">
                <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                    <div className="mixed-img-large-small">
                        {
                            isRequestDetailInProgress.current ? (
                                <img src={loaderGif} alt="loading..." height={20} />
                            ) : (
                                <>
                                    <img className="large" src={baseIcon} alt={farmDetail?.base_name} />
                                    <img className="small" src={quoteIcon} alt={farmDetail?.quote_name} />
                                </>
                            )
                        }
                    </div>
                    <div className="bold-text light-color-text x-small-text">
                        <span className="primary-color-text">{farmDetail?.base_symbol.toUpperCase()}</span>/<span className="secondary-color-text">{farmDetail?.quote_symbol.toUpperCase()}</span>
                    </div>
                    <div className="italic-text bold-text grey-color-text flex-box flex-direction-column small-text">
                        <span>{farm?.data?.baseAmount.toFixed(5)} {farmDetail?.base_symbol?.toUpperCase()}</span>
                        <span>{farm?.data?.quoteAmount.toFixed(5)} {farmDetail?.quote_symbol?.toUpperCase()}</span>
                    </div>
                    <div>
                    {
                        !showDetails ? (
                            <Button 
                                className={"transparent-btn bold-text secondary-color-text italic-text secondary-border-1px"}
                                rightIcon={<MdOutlineKeyboardArrowDown />}
                                innerHTML={showDetails ? "show less" : "show more"}
                                clickMethod={() => setShowDetails(true)}
                            />
                        ) : null
                    }
                    </div>
                </div>
            </div>
            {
                showDetails ? (
                    <div className="full-width animate-grow">
                        <div className="full-width flex-box flex-align-baseline-box flex-justify-apart">
                            <div className="mobile-box medium-z-pd-box">
                                <div className="flex-box flex-align-baseline-box flex-gap-medium flex-justify-apart small-z-pd-box">
                                    <div className="small-text italic-text">Total LP Tokens:</div>
                                    <div className="medium-text bold-text grey-color-text">{formatNumber(parseFloat(farmDetail.lp_tokens).toFixed(4))}</div>
                                </div>
                                <div className="flex-box flex-align-baseline-box flex-gap-medium flex-justify-apart small-z-pd-box">
                                    <div className="small-text italic-text">My LP Tokens:</div>
                                    <div className="medium-text bold-text grey-color-text">{formatNumber(parseFloat(farm.balance).toFixed(4))}</div>
                                </div>
                                <div className="flex-box flex-align-baseline-box flex-gap-medium flex-justify-apart small-z-pd-box">
                                    <div className="small-text italic-text">My Reserve Position</div>
                                    <div className="medium-text bold-text grey-color-text">
                                    {
                                        (parseFloat(farm.balance)/parseFloat(farmDetail.lp_tokens)) * 100 > 0.01 ? `${((parseFloat(farm.balance)/parseFloat(farmDetail.lp_tokens)) * 100).toFixed(2)}%` : "<0.01%"
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-box flex-box flex-direction-column flex-gap-medium large-z-pd-box">
                                <Button 
                                    className={`primary-color-background full-width bold-text ${farm.locked ? "disabled" : null}`}
                                    innerHTML={"Harvest"}
                                    rightIcon={<HiCollection />}
                                    clickMethod={() => harvestMethod(farm)}
                                    disabled={farm.locked}
                                />
                                <Button 
                                    className={"secondary-color-background full-width bold-text"}
                                    innerHTML={"Add Liquidity"}
                                    clickMethod={() => addLiquidityMethod(farm)}
                                    rightIcon={<PiPlantFill />}
                                />
                            </div>
                        </div>
                        <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                            <div></div>
                            <Button 
                                className={"transparent-btn bold-text secondary-color-text italic-text secondary-border-1px"}
                                rightIcon={<MdOutlineKeyboardArrowUp />}
                                innerHTML={"show less"}
                                clickMethod={() => setShowDetails(false)}
                            />
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default Farmed