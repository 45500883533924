import { useState, useEffect, Fragment, useRef } from "react";
import io from "socket.io-client";
import { AMM, formatNumber } from "../Context/general";
import { GoArrowDown } from "react-icons/go";
import { TbSwitch3, TbSettingsBolt } from "react-icons/tb";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import Button from "./Button";
import { useStateValue } from "../Context/StateProvider";
import ToggleButton from "./ToggleButton";

import etherLogo from "../Contents/images/ether-logo.png";
import placeHolderCoin from "../Contents/images/placeholder-coin.png";
import AssetManager from "./AssetManager";
import TokensSelector from "./TokensSelector";
import axios from "axios";
import ConfirmTrade from "./ConfirmTrade";

const Demarcator = ({ switchAssets }) => {
    return (
        <div className="demarcator">
            <div
                className="demarcator-knob hover-spin"
                onClick={switchAssets}>
                <GoArrowDown />
            </div>
        </div>
    )
}

const DexManager = () => {
    const [{ wallet, networks, activeNetwork, slippage, tokens, API_URL, ZERO_ADDRESS }, payload] = useStateValue();
    const [tokenSelector, setTokenSelector] = useState("");
    const [baseAsset, setBaseAsset] = useState({});
    const [quoteAsset, setQuoteAsset] = useState({});
    const [tradeFee, setTradeFee] = useState(0);
    const [tradeSlippage, setTradeSlippage] = useState(0)
    const [alertMessage, setAlertMessage] = useState("");
    const [crossNetworkAssets, setCrossNetworkAssets] = useState(null)
    const [showNetOptions, setShowNetOptions] = useState(false);
    const [showCrossNetOptions, setShowCrossNetOptions] = useState(false);
    const [netTokenUsdValue, setNetTokenUsdValue] = useState(1);
    const [forceTrade, setForceTrade] = useState(false);
    const [crossNetwork, setCrossNetwork] = useState(null);
    const [tradeConfirmation, setTradeConfirmation] = useState(null);
    const [transactionError, setTransactionError] = useState(null)
    const [loadingToken, setLoadingToken] = useState(false);
    const [loadingCrossValue, setLoadingCrossValue] = useState(false);
    const [txDirection, setTxDirection] = useState("base");
    const [platformFee, setPlatformFee] = useState(0.1);

    const netRef = useRef();
    const crossNetRef = useRef();
    const isRequestInProgress = useRef(false);

    const switchAssets = () => {
        const oldBaseAsset = baseAsset;
        const oldQuoteAsset = quoteAsset;

        setBaseAsset(oldQuoteAsset);
        setQuoteAsset(oldBaseAsset);

        if (crossNetwork != null) {
            const oldActiveNet = activeNetwork;
            const oldCrossNet = crossNetwork;

            payload({ type: "ACTIVE-NETWORK", activeNetwork: oldCrossNet });
            setCrossNetwork(oldActiveNet);
        }
    }
    const getOutput = (tokenA, tokenB, amountIn) => {
        const token0 = {...tokenA};
        const token1 = {...tokenB};
        try {
            token0.network = networks.filter((network) => network.chain_id === token0.chain_id)[0];
            token1.network = networks.filter((network) => network.chain_id === token1.chain_id)[0];
            
            token0.reserve0 = token0.address.toLowerCase() === token0.network.usdt.toLowerCase() ? token0.ethToUsdtReserve : token0.ethReserve;
            token0.reserve1 = token0.address.toLowerCase() === token0.network.usdt.toLowerCase() ? token0.usdtReserve : token0.tokenReserve;
            token1.reserve0 = token1.address.toLowerCase() === token1.network.usdt.toLowerCase() ? token1.ethToUsdtReserve : token1.ethReserve;
            token1.reserve1 = token1.address.toLowerCase() === token1.network.usdt.toLowerCase() ? token1.usdtReserve : token1.tokenReserve;

            const token0AMM = new AMM(parseFloat(token0.reserve0), parseFloat(token0.reserve1));
            const token1AMM = new AMM(parseFloat(token1.reserve0), parseFloat(token1.reserve1));
            const token0EtherValue = token0.address.toLowerCase() === ZERO_ADDRESS.toLowerCase() ? amountIn : token0AMM.getAmount0(parseFloat(amountIn));
            const amountOut = token1AMM.getAmount1(token0EtherValue);
            return amountOut;
        } catch(err) {
            console.error(err.message);
            return "";
        }
    }
    const putInput = (tokenB, tokenA, amountIn) => {
        const token0 = {...tokenA};
        const token1 = {...tokenB};
        let amountOut = "";
        try {
            token0.network = networks.filter((network) => network.chain_id === token0.chain_id)[0];
            token1.network = networks.filter((network) => network.chain_id === token1.chain_id)[0];

            if (
                (
                    token0?.address.toLowerCase() === ZERO_ADDRESS.toLowerCase() && 
                    token1?.address.toLowerCase() === token1.network.usdt.toLowerCase()
                ) || (
                    token1?.address.toLowerCase() === ZERO_ADDRESS.toLowerCase() && 
                    token0?.address.toLowerCase() === token0.network.usdt.toLowerCase()
                )
            ) {
                const swapAMM = new AMM(token0.ethToUsdtReserve, token0.usdtReserve);
                amountOut = token0.address.toLowerCase() === ZERO_ADDRESS.toLowerCase() ? swapAMM.getAmount0(amountIn) : swapAMM.getAmount1(amountIn);
            }
            if (
                (
                    token0?.address.toLowerCase() === ZERO_ADDRESS.toLowerCase() && 
                    ![token1.network.usdt.toLowerCase(), ZERO_ADDRESS.toLowerCase()].includes(token1?.address.toLowerCase())
                ) || (
                    token1?.address.toLowerCase() === ZERO_ADDRESS.toLowerCase() && 
                    ![token0.network.usdt.toLowerCase(), ZERO_ADDRESS.toLowerCase()].includes(token0?.address.toLowerCase())
                )
            ) {
                let wethReserve, tokenReserve;
                if (token0?.address.toLowerCase() === ZERO_ADDRESS.toLowerCase()) {
                    wethReserve = token1.ethReserve;
                    tokenReserve = token1.tokenReserve;
                } else {
                    wethReserve = token0.ethReserve;
                    tokenReserve = token0.tokenReserve;
                }
                const swapAMM = new AMM(wethReserve, tokenReserve);
                // amountOut = 
            }
            return amountOut;
        } catch(err) {
            console.error(err.message);
            return "";
        }
    }
    const manageOnChange = async (e) => {
        if (loadingToken) {return}
        const assetCategory = e.target.dataset.category;
        let value = e.target.value;
        
        if (value != "") {
            value = value.toString().replace(" ", "").split("").reduce((p, c) => {
                if (!isNaN(c) || (c == "." && !p.split("").includes("."))) p += c;
                return p;
            }, "");
            const sanitizedValue = parseFloat(value[value.length - 1] == "." ? value.slice(0, value.length - 1) : value);
            let baseValue = value, 
                quoteValue = value;

            if (crossNetwork != null) {
                setLoadingCrossValue(true)
                try {
                    quoteValue = "";
                    // get expected amountOut for cross-chain swap
                    setBaseAsset({ ...baseAsset, amount: baseValue });
                    const {data: response} = await axios.get(`${API_URL}/transactions/cross-swap?from=${baseAsset.symbol.toLowerCase() == "bnb" ? "bnbbsc" : baseAsset.symbol.toLowerCase()}&to=${quoteAsset.symbol.toLowerCase() == "bnb" ? "bnbbsc" : quoteAsset.symbol.toLowerCase()}&amount=${sanitizedValue}`);
                    const {estimatedAmount, networkFee} = response;
                    // set quote value
                    quoteValue = estimatedAmount;
                } catch(err) {
                    // throw error
                    quoteValue = (parseFloat(baseValue) * parseFloat(baseAsset.price))/parseFloat(quoteAsset.price);
                } finally {
                    setLoadingCrossValue(false)
                }
            } else {
                if (assetCategory === "base") {
                    quoteValue = getOutput(baseAsset, quoteAsset, sanitizedValue).toFixed(5);
    
                }
                if (assetCategory === "quote") {
                    console.log(baseValue, quoteAsset, baseAsset)
                    // baseValue = getOutput(quoteAsset, baseAsset, sanitizedValue).toFixed(5);
                    baseValue = getOutput(quoteAsset, baseAsset, sanitizedValue).toFixed(5);
                }
            }

            const expAmountOut = (parseFloat(baseAsset.price) / parseFloat(quoteAsset.price)) * parseFloat(value);
            const expAmountOutUSDValue = parseFloat(baseAsset.price) * parseFloat(expAmountOut);
            const actAmountOutUSDValue = parseFloat(baseAsset.price) * parseFloat(quoteValue);
            const slippage = ((expAmountOutUSDValue - actAmountOutUSDValue) / expAmountOutUSDValue) * 100;
            
            setTradeSlippage(slippage);
            setTradeFee((((parseFloat(baseValue) * parseFloat(baseAsset?.price)) / netTokenUsdValue) * 0.8) / 100);
            setBaseAsset({ ...baseAsset, amount: baseValue });
            setQuoteAsset({ ...quoteAsset, amount: quoteValue });
            setTxDirection(assetCategory);
        } else {
            setBaseAsset({ ...baseAsset, amount: "" });
            setQuoteAsset({ ...quoteAsset, amount: "" });
        }
    }
    const handleCheckOnChange = (e) => {
        setForceTrade(e.target.checked);
    }
    const handleTokenSelection = async (token, category) => {
        try {
            const _token = {...token, amount: ""};
            if (category == "base") {
                setBaseAsset(_token);
                setQuoteAsset({ ...quoteAsset, amount: "" });
            }
            if (category == "quote") {
                setQuoteAsset(_token);
                setBaseAsset({ ...baseAsset, amount: "" });
            }
            // token.price = price;
            setTokenPrice(_token, category, "");
        } catch(err) {}
        setTokenSelector("");
    }
    const showTokenSelector = (category) => {
        if (category == "" || tokens == null) {
            setTokenSelector("");
        }
        if (["base", "quote"].includes(category)) {
            const netTokens = crossNetwork != null && category == "quote" ? crossNetworkAssets : tokens[activeNetwork?.symbol?.toLowerCase()];
            
            if (netTokens != null) {
                setTokenSelector(
                    <TokensSelector
                        tokens={netTokens}
                        category={category}
                        base={baseAsset?.symbol.toLowerCase()}
                        quote={quoteAsset?.symbol.toLowerCase()}
                        closeSelector={() => setTokenSelector("")} 
                        selectMethod={(token) => handleTokenSelection(token, category)}
                    />
                );
            }
        }
    }
    const handleNetSelection = (network) => {
        if ([crossNetwork?.symbol || activeNetwork.symbol, activeNetwork.symbol].includes(network.symbol)) {
            return;
        }
        payload({ type: "ACTIVE-NETWORK", activeNetwork: network });
        setActiveNetworkMethod(network);
        setShowNetOptions(false);
    }
    const handleCrossNetSelection = (network) => {
        const runningNetworks = crossNetwork == null ? [activeNetwork?.symbol] : [crossNetwork?.symbol, activeNetwork?.symbol];
        if (runningNetworks.includes(network.symbol)) {
            return;
        }
        setCrossNetwork(network);
        setShowCrossNetOptions(false);
    }
    const getCrossNetwork = () => {
        return networks.filter(network => network.symbol != activeNetwork.symbol)[0] || null
    }
    const submitTransaction = async (passCode) => {
        payload({
            type: "FETCHING",
            fetching: true
        });
        try {
            axios.defaults.withCredentials = true;
            if (crossNetwork == null) {
                const {address, amount} = baseAsset;
                const fee = 0;
                let method = "swap";
                if (txDirection === "base") method += " exact";
                if (baseAsset.address === ZERO_ADDRESS) method += " eth for";
                else method += " tokens for";
                if (txDirection === "quote") method += " exact";
                if (quoteAsset.address === ZERO_ADDRESS) method += " eth";
                else method += " tokens";
                
                const transactionObject = {
                    fee,
                    method,
                    quoteAsset,
                    asset: address,
                    sender: wallet,
                    txAuth: passCode,
                    amount: parseFloat(amount),
                    chain_id: activeNetwork.chain_id
                }
    
                await axios.post(`${API_URL}/transactions`, transactionObject);
            } else {
                const to = quoteAsset?.symbol.toLowerCase();
                const from = baseAsset?.symbol?.toLowerCase(); 
                const amount = parseFloat(baseAsset.amount);
                const token = baseAsset.address; 
                const fromNetwork = activeNetwork.symbol; 
                const toNetwork = crossNetwork.symbol;
                const transactionObject = {
                    to,
                    from,
                    token,
                    amount,
                    toNetwork,
                    fromNetwork,
                    txAuth: passCode
                }

                await axios.post(`${API_URL}/transactions/cross-swap`, transactionObject);
            }
            const newBaseAsset = {...baseAsset, amount: ""};
            const newQuoteAsset = {...quoteAsset, amount: ""};
            setPairPrice(newBaseAsset, newQuoteAsset);
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "success",
                    message: <span>Swapped {baseAsset.amount} {baseAsset.symbol.toUpperCase()} for {quoteAsset.amount} {quoteAsset.symbol.toUpperCase()} successfully.<br/><a href={`${activeNetwork.explorer}/`}>View transaction on explorer</a></span>
                }
            });
        } catch(err) {
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "error",
                    message: err.response.data.message || err.response.statusText || err.message
                }
            });
        } finally {
            payload({
                type: "FETCHING",
                fetching: false
            });
            setTradeConfirmation(null);
        }
    }
    const handleSubmit = () => {
        if (parseFloat(baseAsset.amount) > 0) {
            setTradeConfirmation(
                <ConfirmTrade
                    tradeFee={tradeFee}
                    baseAsset={baseAsset}
                    onSubmit={submitTransaction}
                    quoteAsset={quoteAsset}
                    tradeSlippage={tradeSlippage}
                    activeNetwork={activeNetwork}
                    netTokenUsdValue={netTokenUsdValue}
                    closeMethod={() => setTradeConfirmation(null)} />
            );
        }
    }
    const setTokenPrice = async (token, pair, source = null) => {
        setLoadingToken(true);
        try {
            if (pair == "base") {setBaseAsset(token);}
            else {setQuoteAsset(token);}
            const tokenBalanceRequest = wallet == null ? null : source == null ?
                axios.get(`${API_URL}/wallet/balance/${token.chain_id}/${token.address}/${wallet}`) :
                axios.get(`${API_URL}/wallet/balance/${token.chain_id}/${token.address}/${wallet}`, {cancelToken: source.token});
            const tokenPriceRequest = source == null || source == "" ?
                axios.get(`${API_URL}/tokens/price/${token.chainSymbol.toLowerCase()}/${token.address}`) :
                axios.get(`${API_URL}/tokens/price/${token.chainSymbol.toLowerCase()}/${token.address}`, {cancelToken: source.token});
            const promises = wallet == null ? [tokenPriceRequest] : [tokenPriceRequest, tokenBalanceRequest]
            const [priceResponse, balanceResponse] = await axios.all(promises);
            const balance = balanceResponse?.data?.balance || null;
            let {price, ethReserve, usdtReserve, tokenReserve, ethToUsdtReserve} = priceResponse.data;
            if (price === 0) {price = (parseFloat(ethReserve)/parseFloat(tokenReserve)) * (parseFloat(usdtReserve)/parseFloat(ethToUsdtReserve))}
            
            if (source != null) {
                const walletTokenData = wallet == null ? 
                    {...token, price, ethReserve, usdtReserve, tokenReserve, ethToUsdtReserve} : 
                    {...token, price, balance, ethReserve, usdtReserve, tokenReserve, ethToUsdtReserve}; 
                if (pair == "base") {setBaseAsset(walletTokenData);}
                else {setQuoteAsset(walletTokenData);}
            }
            setLoadingToken(false);
            
            return price;
        } catch(err) {
            setLoadingToken(false);
        }
    }
    const setPairPrice = async (base, quote, source = null) => {
        setLoadingToken(true);
        setBaseAsset(base);
        setQuoteAsset(quote);
        try {
            if (base === null || quote === null || Object.keys(base).length === 0 || Object.keys(quote).length === 0) throw {message: "missing base or and quote asset(s)"}
            const basePriceRequest =  source == null ?
                axios.get(`${API_URL}/tokens/price/${base.chainSymbol.toLowerCase()}/${base.address}`) :
                axios.get(`${API_URL}/tokens/price/${base.chainSymbol.toLowerCase()}/${base.address}`, {cancelToken: source.token});
            const quotePriceRequest =  source == null ?
                axios.get(`${API_URL}/tokens/price/${quote.chainSymbol.toLowerCase()}/${quote.address}`) :
                axios.get(`${API_URL}/tokens/price/${quote.chainSymbol.toLowerCase()}/${quote.address}`, {cancelToken: source.token});
            const baseBalanceRequest = wallet == null ? null : source == null ?
                axios.get(`${API_URL}/wallet/balance/${base.chain_id}/${base.address}/${wallet}`) :
                axios.get(`${API_URL}/wallet/balance/${base.chain_id}/${base.address}/${wallet}`, {cancelToken: source.token});
            const quoteBalanceRequest = wallet == null ? null : source == null ?
                axios.get(`${API_URL}/wallet/balance/${quote.chain_id}/${quote.address}/${wallet}`) :
                axios.get(`${API_URL}/wallet/balance/${quote.chain_id}/${quote.address}/${wallet}`, {cancelToken: source.token});
            const promises = wallet == null ? 
                [basePriceRequest, quotePriceRequest] : 
                [basePriceRequest, quotePriceRequest, baseBalanceRequest, quoteBalanceRequest]
            
            const [response0, response1, response2, response3] = await axios.all(promises);
            const basePriceData = response0.data;
            const quotePriceData = response1.data;
            const baseBalance = response2?.data?.balance;
            const quoteBalance = response3?.data?.balance;

            base = {...base, ...basePriceData, balance: baseBalance};
            quote = {...quote, ...quotePriceData, balance: quoteBalance};

            base.price = parseFloat(basePriceData.price) == 0 ? 
                (parseFloat(basePriceData.ethReserve)/parseFloat(basePriceData.tokenReserve)) * 
                (parseFloat(basePriceData.usdtReserve)/parseFloat(basePriceData.ethToUsdtReserve)) : 
                basePriceData.price;
            quote.price = parseFloat(quotePriceData.price) == 0 ? 
                (parseFloat(quotePriceData.ethReserve)/parseFloat(quotePriceData.tokenReserve)) * 
                (parseFloat(quotePriceData.usdtReserve)/parseFloat(quotePriceData.ethToUsdtReserve)) : 
                quotePriceData.price;
            base.balance = response2?.data?.balance || null;
            quote.balance = response3?.data?.balance || null;

            setNetTokenUsdValue(base.price);
            setBaseAsset({...base, amount: ""});
            setQuoteAsset({...quote, amount: ""});
            setLoadingToken(false);
        } catch(err) {
            console.error(err)
            setLoadingToken(false);
        }
    }
    const getTokens = (source = null, netChoice = null) => new Promise(async function (resolve) {
        if (isRequestInProgress.current) return;
        isRequestInProgress.current = true;
        try {
            let _activeNetwork = netChoice || activeNetwork;
            if (activeNetwork == null) {
                const _networks = await getNetworks(source);
                _activeNetwork = _networks[0];
            }
            let _tokens = tokens
            if (_tokens == null) {
                setLoadingToken(true);
                const {data: response} = source == null ? 
                    await axios.get(`${API_URL}/tokens`) : 
                    await axios.get(`${API_URL}/tokens`, { cancelToken: source.token });
                _tokens = response.tokens;
                setLoadingToken(false);
                payload({
                    type: "TOKENS",
                    tokens: _tokens
                })
            }
            ;
            const selectedNetworkTokens = _tokens[_activeNetwork?.symbol.toLowerCase() || "eth"];
            
            if (selectedNetworkTokens.length > 1) {
                const _crossNetwork = networks.filter((network) => network.chain_id != _activeNetwork.chain_id)[0];
                const base = selectedNetworkTokens.filter(token => token.symbol.toLowerCase() === _activeNetwork.symbol.toLowerCase())[0];
                const quote = crossNetwork == null ? 
                    selectedNetworkTokens.filter(token => ["dai", "usdt", "usdc", "busd"].includes(token.symbol.toLowerCase()))[0] :
                    tokens[_crossNetwork.symbol.toLowerCase()].filter((token) => token.address == ZERO_ADDRESS)[0];
                
                setPairPrice(base, quote, source);
            }
            isRequestInProgress.current = false;
            return resolve(_tokens);
        } catch(err) {
            console.error(err.message);
            isRequestInProgress.current = false;
            return resolve("FAIL");
        }
    })
    const getNetworks = (source = null) => new Promise(async function (resolve) {
        try {
            const {data: response} = source == null ? 
                await axios.get(`${API_URL}/networks`) : 
                await axios.get(`${API_URL}/networks`, { cancelToken: source.token });
            const {networks} = response;
            payload({
                type: "NETWORKS",
                networks
            });
            if (networks.length > 0 && activeNetwork == null) {
                payload({
                    type: "ACTIVE-NETWORK",
                    activeNetwork: networks.filter(function(network) {return network.symbol.toLowerCase() == "eth"})[0]
                });
            }
            return resolve(networks);
        } catch(err) {
            // console.error(err.message);
            return resolve("FAIL");
        }
    })
    const setActiveNetworkMethod = async (network, source = null) => {
        try {
            if (network == null) {
                const _networks = await getNetworks();
                network = _networks.filter(function(net) {return net.symbol.toLowerCase() == "eth"});
            }
            let _tokens = tokens;
            if (_tokens == null) {
                _tokens = await getTokens(source, network);
            }
            const selectedNetworkTokens = _tokens[network?.symbol.toLowerCase() || "eth"];
            if (selectedNetworkTokens.length > 1) {
                const _crossNetwork = networks.filter((network) => network.chain_id != network.chain_id)[0];
                const base = selectedNetworkTokens.filter(token => token.symbol.toLowerCase() === network.symbol.toLowerCase())[0];
                const quote = crossNetwork == null ? 
                    selectedNetworkTokens.filter(token => ["dai", "usdt", "usdc", "busd"].includes(token.symbol.toLowerCase()))[0] :
                    tokens[_crossNetwork.symbol.toLowerCase()].filter((token) => token.address == ZERO_ADDRESS)[0];
                setBaseAsset(base);
                setQuoteAsset(quote);
                
                setPairPrice(base, quote, source);
            }
            return network;
        } catch(err) {
            console.error(err.message);
            return;
        }
    }
    const setCrossChainMethod = (_crossNetwork, source) => {
        try {
            if (_crossNetwork != null) {
                const initialCrossNetAssets = tokens[_crossNetwork.symbol.toLowerCase()];
                setNetTokenUsdValue(baseAsset.price)
                setCrossNetworkAssets(initialCrossNetAssets);
                const quote = initialCrossNetAssets.filter((asset) => asset.address.toLowerCase() === ZERO_ADDRESS.toLowerCase())[0];
                
                if (baseAsset.price) {
                    setBaseAsset({...baseAsset, amount: ""})
                    setTokenPrice(quote, "quote", source);
                } else {
                    setPairPrice(baseAsset, quote, source);
                }
                
            } else {
                setCrossNetworkAssets(null);
                if (tokens != null && activeNetwork != null) {
                    const selectedTokens = tokens[activeNetwork.symbol.toLowerCase()];
                    const base = selectedTokens.filter(function(token) {return token.symbol.toLowerCase() == activeNetwork.symbol.toLowerCase()})[0];
                    const quote = selectedTokens.filter(function(token) {return ["usdt", "usdc", "dai"].includes(token.symbol.toLowerCase())})[0];
                    
                    setPairPrice(base, quote);
                }
            }
        } catch(err) {console.error(err.message)}
    }

    useEffect(() => {
        const outCLickHandler = (e) => {
            if (netRef?.current && !netRef?.current?.contains(e.target)) {
                setShowNetOptions(false);
            }
            if (crossNetwork != null) {
                if (crossNetRef?.current != null && !crossNetRef?.current.contains(e.target)) {
                    setShowCrossNetOptions(false);
                }
            }
        }
        document.addEventListener("mousedown", outCLickHandler);

        return (() => {
            document.removeEventListener("mousedown", outCLickHandler);
        })
    })
    useEffect(() => {
        const source = axios.CancelToken.source();
        getTokens(source);

        return () => {
            source.cancel();
            isRequestInProgress.current = false;
        }
    }, [])
    useEffect(() => {
        let source;
        if (wallet == null) {
            setBaseAsset({...baseAsset, amount: "", balance: 0});
            setQuoteAsset({...quoteAsset, amount: "", balance: 0});
        } else {
            source = axios.CancelToken.source();
            setPairPrice(baseAsset, quoteAsset, source)
        }
        return () => {
            if (source != null) source.cancel();
        }
    }, [wallet])
    useEffect(() => {
        const source = axios.CancelToken.source();
        setActiveNetworkMethod(activeNetwork, source);

        return () => {
            source.cancel();
            isRequestInProgress.current = false;
        }
    }, [activeNetwork])
    useEffect(() => {
        let source;
        if (crossNetwork === null) {
            setPlatformFee(0.1);
        } else {
            setPlatformFee(0.16);
            source = axios.CancelToken.source();
            setCrossChainMethod(crossNetwork, source);
        }
        return () => {
            if (source != null) source.cancel();
            isRequestInProgress.current = false;
        }
    }, [crossNetwork])
    useEffect(() => {
        if (baseAsset && parseFloat(baseAsset.amount) > 0 && wallet != null) {
            setTransactionError(null);
            if (tradeSlippage > slippage && !forceTrade) {
                setTransactionError("Higher than Slippage Tolerance");
            }
            if (parseFloat(baseAsset?.amount || 0) > parseFloat(baseAsset.balance || 0)) {
                setTransactionError("Insufficient token balance")
            }
        } else {
            setTransactionError(null)
            setAlertMessage("")
        }
    }, [tradeSlippage, baseAsset?.amount, forceTrade])

    return (
        <section className="container-1028 trade-board">
            <div className="centered-box-y box-max-400 flex-box flex-justify-center-box">
                <div className="trade-container full-width header-space">
                    <div className="controls">
                        <div className="full-width flex-box flex-align-center flex-justify-apart">
                            <div className="control-item active">Swap</div>
                        </div>
                        <div className="right-control cursor-pointer flex-box flex-align-center-box flex-gap-large">
                            <TbSettingsBolt
                                className="large-svg"
                                onClick={() => payload({ type: "SETTINGS", showSettings: true })} />
                        </div>
                    </div>
                    <div className="full-width flex-box flex-align-center-box flex-justify-apart flex-gap-medium">
                        <div className="network-selector">
                            <div
                                onClick={() => setShowNetOptions(!showNetOptions)}
                                className="full-width flex-box flex-align-center-box flex-gap-small light-text fade-color-background medium-x-pd-box large-y-pd-box bold-text small-text flex-justify-apart">
                                <div className="flex-box flex-align-center-box flex-gap-medium">
                                    <img className="medium-icon" src={activeNetwork?.thumbnail || etherLogo} alt="" />
                                    <span style={{ whiteSpace: "nowrap" }}>{activeNetwork?.name || "Ethereum"}</span>
                                </div>
                                <MdOutlineKeyboardArrowDown />
                            </div>
                            {
                                showNetOptions ? (
                                    <div ref={netRef} className="network-selections light-text grey-color-background small-z-pd-box bold-text small-text">
                                        {
                                            networks != null ? networks.map((network, index) => (
                                                <div
                                                    onClick={() => handleNetSelection(network)}
                                                    key={index}
                                                    className={`network small-text large-z-pd-box flex-box flex-align-center-box flex-gap-small light-text ${[activeNetwork?.symbol, crossNetwork?.symbol || activeNetwork?.symbol].includes(network?.symbol) ? "selected" : ""}`}>
                                                    <img className="medium-icon" src={network?.thumbnail || placeHolderCoin} alt="" />
                                                    <span>{network?.name}</span>
                                                </div>
                                            )) : (
                                                <div onClick={() => setShowNetOptions(false)} className="full-width flex-box flex-align-justify-center-box medium-z-pd-box light-text">
                                                    <span className="x-small-text">No network(s) found!</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="network-selector">
                            {
                                crossNetwork != null ? (
                                    <Fragment>
                                        <div
                                            onClick={() => setShowCrossNetOptions(!showCrossNetOptions)}
                                            className="full-width flex-box flex-align-center-box flex-gap-small light-text fade-color-background medium-x-pd-box large-y-pd-box bold-text small-text flex-justify-apart">
                                            <div className="flex-box flex-align-center-box flex-gap-medium">
                                                <img className="medium-icon" src={crossNetwork?.thumbnail || etherLogo} alt="" />
                                                <span style={{ whiteSpace: "nowrap" }}>{crossNetwork?.name || "Ethereum"}</span>
                                            </div>
                                            <MdOutlineKeyboardArrowDown />
                                        </div>
                                        {
                                            !showCrossNetOptions ? null : (
                                                <div ref={crossNetRef} className="network-selections light-text grey-color-background small-z-pd-box bold-text small-text">
                                                    {
                                                        networks && networks.length > 0 ? networks.map((network, index) => (
                                                            <div
                                                                onClick={() => handleCrossNetSelection(network)}
                                                                key={index}
                                                                className={`network small-text large-z-pd-box flex-box flex-align-center-box flex-gap-small light-text ${[activeNetwork?.symbol, crossNetwork?.symbol || activeNetwork?.symbol].includes(network?.symbol) ? "selected" : ""}`}>
                                                                <img className="medium-icon" src={network?.thumbnail || placeHolderCoin} alt="" />
                                                                <span>{network?.name}</span>
                                                            </div>
                                                        )) : (
                                                            <div onClick={() => setShowNetOptions(false)} className="full-width flex-box flex-align-justify-center-box medium-z-pd-box light-text">
                                                                <span className="x-small-text">No network(s) found!</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="content-board large-z-pd-box xx-large-x-mg-box">
                            <div className="bold-text flex-box flex-gap-small secondary-color-text">
                                <TbSwitch3 />
                                <span>Cross-Chain Swap</span>
                            </div>
                            <div className="small-text">
                                Swap tokens natively across chains including Ethereum.
                            </div>
                            <ToggleButton changePropState={(state) => state ? setCrossNetwork(getCrossNetwork()) : setCrossNetwork(null)} />
                        </div>
                        <AssetManager  
                            asset={baseAsset} 
                            assetCategory="base" 
                            onChange={manageOnChange} 
                            loadingToken={loadingToken}
                            openTokenSelector={() => showTokenSelector("base")} 
                        />
                        <Demarcator switchAssets={switchAssets} />
                        <AssetManager 
                            asset={quoteAsset} 
                            assetCategory="quote" 
                            onChange={manageOnChange} 
                            loadingToken={loadingToken}
                            isReadOnly={crossNetwork != null}
                            openTokenSelector={() => showTokenSelector("quote")} 
                        />
                        <div className="full-width flex-box flex-align-center-box flex-justify-end large-x-mg-box">
                            <span className="small-text bold-text"><span className="secondary-color-text x-small-text">Slippage Tolerance</span> {slippage}%</span>
                        </div>
                        <div className="trade-data">
                            <Button
                                className={`primary-color-background full-width ${(!baseAsset?.amount || baseAsset?.amount == "" || baseAsset?.amount < 0) && wallet != null || loadingCrossValue ? "disabled" : transactionError != null ? "disabled-with-error" : ""}`}
                                innerHTML={wallet != null && transactionError == null && parseFloat(baseAsset?.amount || 0) > 0 ? "Trade Now" : wallet != null && transactionError == null && parseFloat(baseAsset?.amount || 0) <= 0 ? "Input Amount" : transactionError != null ? transactionError : "Connect Wallet"}
                                clickMethod={wallet != null && transactionError == null ? () => handleSubmit() : () => payload({
                                    type: "WALLET_CONNECT_VISIBILITY",
                                    showConnectWallet: true
                                })}
                                disabled={transactionError != null}
                            />

                            {
                                wallet != null && !loadingCrossValue && alertMessage && alertMessage != "" ? (
                                    <div className="full-width alert-box">{alertMessage}</div>
                                ) : null
                            }
                            {
                                wallet != null && !loadingCrossValue && tradeSlippage > slippage && parseFloat(baseAsset.amount) > 0 ? (
                                    <div className="full-width alert-box">
                                        <div 
                                            className={`alert-box full-width large-x-mg-box large-z-pd-box ${forceTrade ? "success" : "danger"}`} 
                                            style={{fontWeight: 100}}>
                                            <label htmlFor="force-trade">
                                                <input type="checkbox" name="force-trade" id="force-trade" checked={forceTrade} onChange={handleCheckOnChange} />
                                                <span>
                                                    The price impact of this trade is higher than the default trade slippage tolerance you have setup, risking a loss greater than you have chosen. If you wish to proceed, please check the box or set your slippage tolerance to a higher value to avoid getting this warning next time.</span>
                                            </label>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                wallet != null && parseFloat(baseAsset?.amount) > 0 ? (
                                    <div className="trade-details full-width large-x-mg-box">
                                        <div className="full-width small-z-pd-box flex-box flex-align-center-box flex-justify-apart">
                                            <span className="title small-text bold-text grey-color-text">Price Slippage</span>
                                            <span className="value small-text" style={tradeSlippage > slippage ? { color: "red" } : { color: "green" }}>{parseFloat(tradeSlippage).toFixed(2)}%</span>
                                        </div>
                                        <div className="full-width small-z-pd-box flex-box flex-align-center-box flex-justify-apart">
                                            <span className="title small-text bold-text grey-color-text">Est. received</span>
                                            <span className="value small-text">{formatNumber(parseFloat(quoteAsset?.amount))} {quoteAsset?.symbol.toUpperCase()}</span>
                                        </div>
                                        <div className="full-width small-z-pd-box flex-box flex-align-center-box flex-justify-apart">
                                            <span className="title small-text bold-text grey-color-text">Min. received</span>
                                            <span className="value small-text">{formatNumber(parseFloat(quoteAsset?.amount) - ((parseFloat(quoteAsset?.amount) * (tradeSlippage + 0.8)) / 100))} {quoteAsset?.symbol.toUpperCase()}</span>
                                        </div>
                                        <div className="full-width small-z-pd-box flex-box flex-align-center-box flex-justify-apart">
                                            <span className="title small-text bold-text grey-color-text">Platform fee</span>
                                            <span className="value small-text">{platformFee}%</span>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {tokenSelector}
            {tradeConfirmation}
        </section>
    )
}

export default DexManager