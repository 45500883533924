import React, { Fragment, useEffect, useState } from 'react'
import { CgMenuGridR } from 'react-icons/cg';
import { ImMenu } from 'react-icons/im';
import { IoSearchOutline } from 'react-icons/io5';
import { useStateValue } from '../Context/StateProvider';
import PoolsBlock from './templates/PoolsBlock';
import PoolsGrid from './templates/PoolsGrid';
import axios from 'axios';
import etherLogo from "../Contents/images/ether-logo.png";
import placeHolderCoin from "../Contents/images/placeholder-coin.png";
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import PoolForm from './PoolForm';
import ToggleButton from './ToggleButton';
import { loadImage } from '../Context/general';

const Pools = () => {
	const [{ poolsViewType, API_URL, activeNetwork, networks }, payload] = useStateValue();
	const [searchQuery, setSearchQuery] = useState();
	const [pools, setPools] = useState([]);
	const [showNetOptions, setShowNetOptions] = useState(false);
	const [showOnlyStaked, setShowOnlyStaked] = useState(false);

	const getPools = async ({ source = null, network = null }) => {
		try {
			const _activeNetwork = network === null ? activeNetwork : network;
			const { data: response } = source === null ?
				await axios.get(`${API_URL}/pools/chainId/${_activeNetwork.chain_id}`) :
				await axios.get(`${API_URL}/pools/chainId/${_activeNetwork.chain_id}`, { cancelToken: source.token });
			const { pools } = response;
			setPools(pools);
		} catch (err) {

		}
	}
	const handleNetSelection = (network) => {
		if (activeNetwork.symbol == network.symbol) {
			return;
		}
		payload({ type: "ACTIVE-NETWORK", activeNetwork: network });
		setShowNetOptions(false);
		getPools({ network });
	}

	useEffect(() => {
		const source = axios.CancelToken.source();
		getPools({ source });
		return () => {
			source.cancel();
		}
	}, [])

	return (
		<Fragment>
			<section className="container-1028 trade-board flex-box flex-direction-column">
				<div className="dex-banner full-width secondary-color-background dark-color-text large-z-pd-box flex-box flex-align-center-box flex-shrink-0">
					<div className="banner-text large-z-pd-box large-text bold-text">
						<h1 className="bold-text grey-color-text">Stake to Earn</h1>
						<div className="style-reenie">
							Maximize your earnings. Stake into liquidity pool(s) now and earn massive rewards. Start today and watch your investments grow exponentially! T&C apply.
						</div>
					</div>
					<div className="centered-bottom-floater">
						Earn upto <span className="green-text">312% APR</span>
					</div>
				</div>
				<div className="full-width flex-box flex-align-center-box flex-gap-large flex-shrink-0 dark-color-background light-color-text sort-bar">
					<div className="flex-box flex-align-center-box flex-gap-small grey-color-background medium-z-pd-box">
						<input
							type="text"
							id="search-query"
							name="search-query"
							value={searchQuery}
							placeholder="Search for pool(s)"
							onChange={(e) => setSearchQuery(e.target.value)}
							className="medium-text medium-z-pd-box dark-color-background no-border light-color-text"
						/>
						<label htmlFor="search-query">
							<IoSearchOutline className="large-icon" />
						</label>
					</div>
					<div className="flex-box flex-align-center-box">
						<div className="flex-box flex-align-center-box flex-gap-medium  full-height">
							<CgMenuGridR className={`large-icon cursor-pointer ${poolsViewType == "grid" ? "secondary-color-text" : ""}`} onClick={() => payload({ type: "POOLS_VIEW_TYPE", view: "grid" })} />
							{/* <ImMenu className={`large-icon cursor-pointer ${poolsViewType == "block" ? "secondary-color-text" : ""}`} onClick={() => payload({ type: "POOLS_VIEW_TYPE", view: "block" })} /> */}
							<div className="bold-text small-text">View</div>
						</div>
						<div className="medium-y-mg-box flex-box flex-align-center-box flex-gap-medium full-height">
							<div className="bold-text small-text">Staked only</div>
							<ToggleButton changePropState={() => setShowOnlyStaked(!showOnlyStaked)} propState={showOnlyStaked} />
						</div>
						<div className="container-70">
							<div className="network-selector">
								<div
									onClick={() => setShowNetOptions(!showNetOptions)}
									className="full-width flex-box flex-align-center-box flex-gap-small light-text fade-color-background medium-x-pd-box large-y-pd-box bold-text small-text flex-justify-apart">
									<div className="flex-box flex-align-center-box flex-gap-medium">
										<img className="medium-icon" src={activeNetwork?.thumbnail ? loadImage(activeNetwork?.thumbnail) : etherLogo} alt="" />
									</div>
									<MdOutlineKeyboardArrowDown />
								</div>
								{
									showNetOptions ? (
										<div className="network-selections light-text grey-color-background small-z-pd-box bold-text small-text">
											{
												networks != null ? networks.map((network, index) => (
													<div
														onClick={() => handleNetSelection(network)}
														key={index}
														className={`network small-text large-z-pd-box flex-box flex-align-center-box flex-gap-small light-text ${activeNetwork?.symbol == network?.symbol ? "selected" : ""}`}>
														<img className="medium-icon" src={network?.thumbnail ? loadImage(network?.thumbnail) : placeHolderCoin} alt="" />
														<span>{network?.name}</span>
													</div>
												)) : (
													<div onClick={() => setShowNetOptions(false)} className="full-width flex-box flex-align-justify-center-box medium-z-pd-box light-text">
														<span className="x-small-text">No network(s) found!</span>
													</div>
												)
											}
										</div>
									) : null
								}
							</div>
						</div>
					</div>
				</div>
				<div className="full-width large-x-mg-box flex-grow-1 scroll-y">
					<div className="full-width">
						{
							poolsViewType == "block" ? (
								<PoolsBlock pools={pools} showOnlyStaked={showOnlyStaked} />
							) : (
								<PoolsGrid pools={pools} showOnlyStaked={showOnlyStaked} />
							)
						}
					</div>
				</div>
			</section>
			<PoolForm />
		</Fragment>
	)
}

export default Pools